import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import axios from 'axios';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { db, rtdb } from '../firebase';
import { ref, update } from 'firebase/database';
import { Dialog, DialogPanel } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import image4 from '../assets/images/image4.png'; // Import the logo image
import { motion } from 'framer-motion';
import { ShoppingBagIcon } from '@heroicons/react/24/outline';
import { FiUser, FiHome, FiMapPin, FiPhone, FiMail } from 'react-icons/fi';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';


interface CartItem {
  totalEarnings: number;
  itemName: string;
  isbn: string;
  quantity: number;
  imageLink: string;
  initialEarnings: number;
  dimensions: {
    length: number;
    width: number;
    height: number;
  };
  weight: number;
}

interface FormData {
  name: string;
  street1: string;
  street2: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  paymentMethod: string;
  paypalEmail: string;
}

const ReviewShipment: React.FC = () => {
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();

  const [shoppingCart, setShoppingCart] = useState<CartItem[]>([]);
  const [formData, setFormData] = useState<FormData | null>(null);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [totalValues, setTotalValues] = useState(0);
  const [totalQuantities, setTotalQuantities] = useState(0);
  const [totalLength, setTotalLength] = useState(0);
  const [totalWidth, setTotalWidth] = useState(0);
  const [totalHeight, setTotalHeight] = useState(0);
  const [totalWeight, setTotalWeight] = useState(0);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [loading, setLoading] = useState(false); // New loading state
  const [isFormValid, setIsFormValid] = useState(true);



  const STATES: Record<string, string> = {
    AL: 'Alabama', AZ: 'Arizona', AR: 'Arkansas', CA: 'California', CO: 'Colorado',
    CT: 'Connecticut', DE: 'Delaware', FL: 'Florida', GA: 'Georgia', ID: 'Idaho',
    IL: 'Illinois', IN: 'Indiana', IA: 'Iowa', KS: 'Kansas', KY: 'Kentucky',
    LA: 'Louisiana', ME: 'Maine', MD: 'Maryland', MA: 'Massachusetts', MI: 'Michigan',
    MN: 'Minnesota', MS: 'Mississippi', MO: 'Missouri', MT: 'Montana', NE: 'Nebraska',
    NV: 'Nevada', NH: 'New Hampshire', NJ: 'New Jersey', NM: 'New Mexico', NY: 'New York',
    NC: 'North Carolina', ND: 'North Dakota', OH: 'Ohio', OK: 'Oklahoma', OR: 'Oregon',
    PA: 'Pennsylvania', RI: 'Rhode Island', SC: 'South Carolina', SD: 'South Dakota',
    TN: 'Tennessee', TX: 'Texas', UT: 'Utah', VT: 'Vermont', VA: 'Virginia', WA: 'Washington',
    WV: 'West Virginia', WI: 'Wisconsin', WY: 'Wyoming'
  };

  // Generate the state options
  const stateOptions = Object.keys(STATES).map((abbr) => (
    <option key={abbr} value={STATES[abbr]}>
      {STATES[abbr]}
    </option>
  ));

  useEffect(() => {
    if (!currentUser) {
      navigate('/auth');
    } else {
      const fetchSavedAddress = async () => {
        const addressRef = doc(db, 'addresses', currentUser.uid);
        const addressDoc = await getDoc(addressRef);
        return addressDoc.exists() ? (addressDoc.data() as FormData) : null;
      };

      const fetchCartDetails = async () => {
        const cartRef = doc(db, 'carts', currentUser.uid);
        const cartDoc = await getDoc(cartRef);
        if (cartDoc.exists()) {
          const cartData = cartDoc.data() as { shopping_cart: CartItem[], totalValues: number, totalQuantities: number };
          calculateTotals(cartData.shopping_cart);
          return cartData.shopping_cart;
        }
        return [];
      };

      const fetchData = async () => {
        try {
          const [addressData, cartData] = await Promise.all([fetchSavedAddress(), fetchCartDetails()]);
      
          if (addressData) {
            setFormData(addressData);
          } else {
            toast.error('No address found, please save your address details first.', {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            navigate('/your-account');
          }
      
          if (cartData.length > 0) {
            setShoppingCart(cartData);
          } else {
            toast.warning('No shopping cart found, please add items to your cart first.', {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            navigate('/get-offer');
          }
        } catch (error) {
          toast.error('Error fetching data, please try again.', {
            position: 'top-right',
            autoClose: 5000,
          });
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }
  }, [currentUser, navigate]);

  const calculateTotals = (cart: CartItem[]) => {
    const totalValues = cart.reduce((acc, item) => acc + (item.totalEarnings || 0), 0);
    const totalQuantities = cart.reduce((acc, item) => acc + (item.quantity || 0), 0);
    const totalWeight = cart.reduce((acc, item) => acc + (item.weight * item.quantity || 0), 0);

    const totalDimensions = cart.reduce((acc, item) => {
      const length = item.dimensions && item.dimensions.length ? item.dimensions.length : 0;
      const width = item.dimensions && item.dimensions.width ? item.dimensions.width : 0;
      const height = item.dimensions && item.dimensions.height ? item.dimensions.height : 0;

      acc.length = Math.max(acc.length, length);
      acc.width = Math.max(acc.width, width);
      acc.height += height * (item.quantity || 1);

      return acc;
    }, { length: 0, width: 0, height: 0 });

    setTotalValues(totalValues);
    setTotalQuantities(totalQuantities);
    setTotalLength(totalDimensions.length);
    setTotalWidth(totalDimensions.width);
    setTotalHeight(totalDimensions.height);
    setTotalWeight(totalWeight);
  };

  const validateField = (name: string, value: string | undefined) => {
    if (!value) {
      return 'This field is required';
    }
    if (name === 'zip' && !/^\d{5}$/.test(value)) {
      return 'Invalid zip code';
    }
    if (name === 'phone' && !/^[0-9-]+$/.test(value)) {
      return 'Phone number should contain only digits and dashes';
    }
    if (name === 'state' && !Object.keys(STATES).includes(value.toUpperCase()) && !Object.values(STATES).includes(value)) {
      return 'Invalid state';
    }
    return null;
  };
  
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
  
    setFormData(prev => {
      const updatedFormData = prev || {
        name: '',
        street1: '',
        street2: '',
        city: '',
        state: '',
        zip: '',
        phone: '',
        paymentMethod: '',
        paypalEmail: ''
      };
  
      // Store the state abbreviation instead of the full state name
      if (name === 'state') {
        const selectedState = Object.keys(STATES).find(abbr => abbr === value) || value;
        return Object.assign({}, updatedFormData, { state: selectedState });

      }
  
      return Object.assign({}, updatedFormData, { [name]: value });

    });
  
    // Validate the field and update the error state
    const error = validateField(name, value);
    setErrors(prev => Object.assign({}, prev, { [name]: error || '' }));

    // Update Firestore if there's no error
    if (!error && currentUser) {
      const addressRef = doc(db, 'addresses', currentUser.uid);
      setDoc(addressRef, Object.assign({}, formData, { [name]: value }), { merge: true });

    }
  };
  
  
  const validateForm = () => {
    const requiredFields = ['name', 'street1', 'city', 'state', 'zip', 'phone', 'paymentMethod'];
    let isValid = true;
    const newErrors: Record<string, string> = {};
  
    requiredFields.forEach(field => {
      const value = formData && formData[field as keyof FormData] ? formData[field as keyof FormData] : undefined;
      const error = validateField(field, value);
      if (error) {
        newErrors[field] = error;
        isValid = false;
      }
    });
  
    setErrors(newErrors);
    setIsFormValid(isValid); // Update form validation state
    return isValid;
  };
  

  const handleCreateShipment = async () => {
    if (!currentUser) {
      toast.error('You have to sign in if you want to create a shipment.', {
        position: 'top-right',
        autoClose: 5000,
      });
      navigate('/auth');
      return;
    }
  
    const filteredCart = shoppingCart.filter(item => item.totalEarnings !== 0);
  
    if (!formData || !validateForm()) {
      toast.error('Please correct the errors in the form before proceeding.', {
        position: 'top-right',
        autoClose: 5000,
      });
      return;
    }
  
    setLoading(true); // Set loading state to true when the process starts
  
    try {
      const items = filteredCart.map(item => ({
        itemName: item.itemName,
        isbn: item.isbn,
        totalEarnings: item.totalEarnings,
        quantity: item.quantity,
      }));
  
      // Get the current date and time
      const now = new Date();
      const formattedDate = now.toLocaleString(); // This will give you a human-readable date-time format
  
      const shipmentData = Object.assign({}, formData, {
        length: totalLength || 10,
        width: totalWidth || 10,
        height: totalHeight || 10,
        weight: totalWeight || 10, // Ensure the weight is in pounds
        user_id: currentUser && currentUser.uid ? currentUser.uid : null,
        email: currentUser && currentUser.email ? currentUser.email : '',
        total_values: totalValues,
        total_quantities: totalQuantities,
        items: items,
        dateCreated: formattedDate, // Add the date and time when the shipment is created
      });
  
      const response = await axios.post('https://resellerapp-backend.azurewebsites.net/submit_shipment', shipmentData);
      const { label_url, tracking_url } = response.data;
  
      // Clear the shopping cart after shipment creation
      setShoppingCart([]);
  
      if (currentUser) {
        const cartRef = doc(db, 'carts', currentUser.uid);
        await setDoc(cartRef, { shopping_cart: [] });
  
        const cartRtdbRef = ref(rtdb, `carts/${currentUser.uid}`);
        await update(cartRtdbRef, { shopping_cart: [] });
      } else {
        localStorage.removeItem('shoppingCart');
      }
  
      navigate(`/shipment_success?label_url=${encodeURIComponent(label_url)}&tracking_url=${encodeURIComponent(tracking_url)}`);
    } catch (error) {
      console.error('Error creating shipment:', error);
  
      if (axios.isAxiosError(error)) {
        const errorMessage = error.response && error.response.data && error.response.data.error
  ? error.response.data.error
  : 'An error occurred while creating the shipment.';
        toast.error(`Error creating shipment: ${errorMessage}`, {
          position: 'top-right',
          autoClose: 5000,
        });
      } else {
        toast.error('An unknown error occurred while creating the shipment.', {
          position: 'top-right',
          autoClose: 5000,
        });
      }
    } finally {
      setLoading(false); // Set loading state to false after the process ends
    }
  };


  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-b from-[#f5e4c3] via-[#cfd9df] to-[#121212]">
      {/* Toast notifications */}
        <ToastContainer />
        <header className="w-full bg-[#f5deb3] shadow-md p-4 fixed top-0 left-0 flex justify-between items-center z-50 border-b border-gray-200">
          <div className="flex items-center justify-center flex-1">
            <Link to="/" className="text-2xl font-bold text-gray-900 flex items-center space-x-2">
              <img
                alt="BooksAreReborn Logo"
                src={image4}
                className="h-12 w-auto"
              />
              <span>BooksAreReborn</span>
            </Link>
            <nav className="hidden lg:flex space-x-4 mx-auto">
              <Link to="/" className="text-gray-900 hover:text-white hover:bg-gray-700 transition-colors duration-300 px-3 py-2 rounded-md text-lg font-medium">
                Home
              </Link>
              <Link to="/your-account" className="text-gray-900 hover:text-white hover:bg-gray-700 transition-colors duration-300 px-3 py-2 rounded-md text-lg font-medium">
                Account
              </Link>
              {currentUser && currentUser.email === '03omerbar@gmail.com' && (
                <Link to="/admin-panel" className="text-gray-900 hover:text-white hover:bg-gray-700 transition-colors duration-300 px-3 py-2 rounded-md text-lg font-medium">
                  Admin Panel
                </Link>
              )}
            </nav>
          </div>
          <div className="flex items-center space-x-4">
            {currentUser ? (
              <button onClick={logout} className="hidden lg:block bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded-full shadow transition">
                Log out
              </button>
            ) : (
              <>
                <button onClick={() => navigate('/auth')} className="hidden lg:block bg-green-600 hover:bg-green-700 text-white px-4 py-2 rounded-full shadow transition">
                  Sign In
                </button>
                <button onClick={() => navigate('/auth', { state: { isLogin: false } })} className="hidden lg:block bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-full shadow transition">
                  Sign Up
                </button>
              </>
            )}
            <button
              type="button"
              onClick={() => setMobileMenuOpen(true)}
              className="lg:hidden p-2 rounded-full bg-gray-900 text-white"
            >
              <Bars3Icon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
        </header>

        <Dialog open={mobileMenuOpen} onClose={() => setMobileMenuOpen(false)} className="lg:hidden">
          <div className="fixed inset-0 z-50" />
          <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-[#f5deb3] px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <Link to="/" className="-m-1.5 p-1.5 flex items-center space-x-2">
                <img
                  alt="BooksAreReborn Logo"
                  src={image4}
                  className="h-12 w-auto"
                />
              </Link>
              <button
                type="button"
                onClick={() => setMobileMenuOpen(false)}
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
              >
                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  <Link
                    to="/"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Home
                  </Link>
                  <Link
                    to="/your-account"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Account
                  </Link>
                  {currentUser && currentUser.email === '03omerbar@gmail.com' && (
                    <Link
                      to="/admin-panel"
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      Admin Panel
                    </Link>
                  )}
                </div>
                <div className="py-6">
                  {currentUser ? (
                    <button onClick={logout} className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                      Log out
                    </button>
                  ) : (
                    <>
                      <button onClick={() => navigate('/auth')} className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                        Sign In
                      </button>
                      <button onClick={() => navigate('/auth', { state: { isLogin: false } })} className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                        Sign Up
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </DialogPanel>
        </Dialog>
        <main className="pt-24 w-full max-w-4xl px-4 mx-auto sm:px-2 md:px-4">
    <section className="mb-12 bg-gradient-to-r from-gray-100 via-gray-200 to-gray-100 p-6 rounded-3xl shadow-2xl sm:p-4">
      <h1 className="text-4xl font-extrabold text-center mb-6 text-gray-800 tracking-tight sm:text-2xl">
        Review Your Shipment
      </h1>

      <motion.div 
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.4 }}
        className="space-y-8"
      >           
        {/* Items section */}
        <div className="bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transition duration-300 sm:p-4">
          <h2 className="text-2xl font-semibold mb-4 flex items-center space-x-2 text-gray-700 sm:text-xl">
            <ShoppingBagIcon className="w-6 h-6 text-gray-500" />
            <span>Items</span>
          </h2>
          <ul className="list-disc pl-6 space-y-4">
            {shoppingCart.map((item, index) => (
              <motion.li
                key={index}
                initial={{ opacity: 0, x: -30 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.3, delay: index * 0.1 }}
                className="bg-white p-3 rounded-lg shadow-lg border border-gray-200 hover:shadow-xl transform transition duration-300 sm:p-3"
              >
                <div className="flex items-start space-x-4 sm:space-x-0 sm:flex-col sm:items-center">
                  <img
                    src={item.imageLink}
                    alt={item.itemName}
                    className="w-20 h-20 object-cover rounded-lg shadow-md sm:w-24 sm:h-auto"
                  />
                  <div className="flex-1">
                    <p className="font-semibold text-gray-800 text-lg sm:text-md">
                      {item.itemName} <span className="text-sm text-gray-500">(ISBN: {item.isbn})</span>
                    </p>
                    <p className="text-sm text-gray-600">
                      Quantity: <span className="font-medium text-gray-800">{item.quantity}</span>
                    </p>
                    <p className="text-sm text-gray-600">
                      Total Earnings: <span className="font-medium text-green-700">${item.totalEarnings.toFixed(2)}</span>
                    </p>
                  </div>
                </div>
              </motion.li>
            ))}
          </ul>
        </div>
              {formData && (
                <>
                  <div className="bg-white p-10 sm:p-6 rounded-2xl shadow-xl backdrop-filter backdrop-blur-xl max-w-3xl mx-auto border-t-4 border-indigo-500">
                    <h2 className="text-4xl sm:text-2xl font-extrabold text-gray-900 mb-6 tracking-tight text-center">
                      Address Details
                    </h2>
                    <form className="space-y-6">
                      {/* Form inputs */}
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div>
                          <label className="text-lg sm:text-base font-semibold text-gray-800 flex items-center space-x-2">
                            <FiUser className="h-6 w-6 text-indigo-600" />
                            <span>Name</span>
                          </label>
                          <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            className="mt-2 block w-full p-4 sm:p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:border-indigo-600"
                          />
                          {errors.name && <p className="text-red-600 text-sm mt-1">{errors.name}</p>}
                        </div>

                        <div>
                          <label className="text-lg sm:text-base font-semibold text-gray-800 flex items-center space-x-2">
                            <FiHome className="h-6 w-6 text-indigo-600" />
                            <span>Street 1</span>
                          </label>
                          <input
                            type="text"
                            name="street1"
                            value={formData.street1}
                            onChange={handleChange}
                            className="mt-2 block w-full p-4 sm:p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:border-indigo-600"
                          />
                          {errors.street1 && <p className="text-red-600 text-sm mt-1">{errors.street1}</p>}
                        </div>
                      </div>

                      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div>
                          <label className="text-lg sm:text-base font-semibold text-gray-800 flex items-center space-x-2">
                            <FiHome className="h-6 w-6 text-indigo-600" />
                            <span>Street 2</span>
                          </label>
                          <input
                            type="text"
                            name="street2"
                            value={formData.street2}
                            onChange={handleChange}
                            className="mt-2 block w-full p-4 sm:p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:border-indigo-600"
                          />
                        </div>

                        <div>
                          <label className="text-lg sm:text-base font-semibold text-gray-800 flex items-center space-x-2">
                            <FiMapPin className="h-6 w-6 text-indigo-600" />
                            <span>City</span>
                          </label>
                          <input
                            type="text"
                            name="city"
                            value={formData.city}
                            onChange={handleChange}
                            className="mt-2 block w-full p-4 sm:p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:border-indigo-600"
                          />
                          {errors.city && <p className="text-red-600 text-sm mt-1">{errors.city}</p>}
                        </div>
                      </div>

                      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                      <div>
                        <label className="text-lg sm:text-base font-semibold text-gray-800 flex items-center space-x-2">
                          <FiMapPin className="h-6 w-6 text-indigo-600" />
                          <span>State</span>
                        </label>
                        <div className="relative">
                        <select
                          name="state"
                          value={formData && formData.state ? formData.state : ''}
                          onChange={handleChange}
                          className="mt-2 block w-full p-4 sm:p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:border-indigo-600"
                        >
                          <option value="" disabled>Select a state</option>
                          {stateOptions}  {/* Use stateOptions here */}
                        </select>
                          {errors.state && <p className="text-red-600 text-sm mt-1">{errors.state}</p>}
                        </div>
                      </div>
                        <div>
                          <label className="text-lg sm:text-base font-semibold text-gray-800 flex items-center space-x-2">
                            <FiMapPin className="h-6 w-6 text-indigo-600" />
                            <span>Zip</span>
                          </label>
                          <input
                            type="text"
                            name="zip"
                            value={formData.zip}
                            onChange={handleChange}
                            className="mt-2 block w-full p-4 sm:p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:border-indigo-600"
                          />
                          {errors.zip && <p className="text-red-600 text-sm mt-1">{errors.zip}</p>}
                        </div>
                      </div>

                      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div>
                          <label className="text-lg sm:text-base font-semibold text-gray-800 flex items-center space-x-2">
                            <FiPhone className="h-6 w-6 text-indigo-600" />
                            <span>Phone</span>
                          </label>
                          <input
                            type="text"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            className="mt-2 block w-full p-4 sm:p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:border-indigo-600"
                          />
                          {errors.phone && <p className="text-red-600 text-sm mt-1">{errors.phone}</p>}
                        </div>

                        <div>
                          <label className="text-lg sm:text-base font-semibold text-gray-800 flex items-center space-x-2">
                            <FiMail className="h-6 w-6 text-indigo-600" />
                            <span>Pay me by</span>
                          </label>
                          <select
                            name="paymentMethod"
                            value={formData.paymentMethod}
                            onChange={handleChange}
                            className="mt-2 block w-full p-4 sm:p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:border-indigo-600"
                          >
                            <option value="" disabled>Select</option>
                            <option value="Check">Check</option>
                            <option value="PayPal">PayPal</option>
                          </select>
                          {errors.paymentMethod && <p className="text-red-600 text-sm mt-1">{errors.paymentMethod}</p>}
                        </div>
                      </div>

                      {formData.paymentMethod === 'PayPal' && (
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                          <div>
                            <label className="text-lg sm:text-base font-semibold text-gray-800 flex items-center space-x-2">
                              <FiMail className="h-6 w-6 text-indigo-600" />
                              <span>PayPal Email</span>
                            </label>
                            <input
                              type="email"
                              name="paypalEmail"
                              value={formData.paypalEmail}
                              onChange={handleChange}
                              className="mt-2 block w-full p-4 sm:p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:border-indigo-600"
                            />
                            {errors.paypalEmail && <p className="text-red-600 text-sm mt-1">{errors.paypalEmail}</p>}
                          </div>
                        </div>
                      )}
                    </form>
                  </div>

                  {/* Shipment details section */}
                  <div className="bg-white p-8 sm:p-6 rounded-xl shadow-lg max-w-4xl mx-auto my-8 border-t-4 border-indigo-600">
                    <h2 className="text-3xl sm:text-2xl font-bold text-gray-900 mb-6 tracking-tight text-center">Shipment Details</h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                      <div className="flex items-center space-x-4">
                        <span className="text-indigo-500">
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 3h18v18H3z" />
                          </svg>
                        </span>
                        <p className="text-lg sm:text-base text-gray-700">Total Length: <span className="font-bold">{totalLength.toFixed(2)} inches</span></p>
                      </div>
                      <div className="flex items-center space-x-4">
                        <span className="text-indigo-500">
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 3h18v18H3z" />
                          </svg>
                        </span>
                        <p className="text-lg sm:text-base text-gray-700">Total Width: <span className="font-bold">{totalWidth.toFixed(2)} inches</span></p>
                      </div>
                      <div className="flex items-center space-x-4">
                        <span className="text-indigo-500">
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 3h18v18H3z" />
                          </svg>
                        </span>
                        <p className="text-lg sm:text-base text-gray-700">Total Height: <span className="font-bold">{totalHeight.toFixed(2)} inches</span></p>
                      </div>
                      <div className="flex items-center space-x-4">
                        <span className="text-indigo-500">
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 3h18v18H3z" />
                          </svg>
                        </span>
                        <p className="text-lg sm:text-base text-gray-700">Total Items: <span className="font-bold">{totalQuantities}</span></p>
                      </div>
                      <div className="flex items-center space-x-4">
                        <span className="text-indigo-500">
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 3h18v18H3z" />
                          </svg>
                        </span>
                        <p className="text-lg sm:text-base text-gray-700">Total Earnings: <span className="font-bold">${totalValues.toFixed(2)}</span></p>
                      </div>
                      <div className="flex items-center space-x-4">
                        <span className="text-indigo-500">
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 3h18v18H3z" />
                          </svg>
                        </span>
                        <p className="text-lg sm:text-base text-gray-700">Total Weight: <span className="font-bold">{totalWeight.toFixed(2)} pounds</span></p>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </motion.div>
            <div className="flex flex-col items-center mt-10 space-y-6">
              <Link
                to="/get-offer"
                className="text-red-600 underline text-lg font-medium hover:text-red-800 transition-colors duration-200 mb-4"
              >
                Go back to Offer Page
              </Link>
              {loading && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5 }}
                  className="text-center text-indigo-600 font-extrabold mb-4 text-xl bg-blue-100 p-4 rounded-lg shadow-sm"
                >
                  Your shipment is being created... Please wait.
                </motion.div>
              )}
              <button
                onClick={handleCreateShipment}
                disabled={loading || !isFormValid}
                className={`w-full max-w-xs px-8 py-3 sm:px-4 sm:py-2 text-lg font-semibold rounded-full shadow-lg transition-transform duration-300 ease-in-out transform ${
                  loading || !isFormValid
                    ? 'bg-gray-300 text-gray-500 cursor-not-allowed opacity-50'
                    : 'bg-indigo-600 text-white hover:bg-indigo-700 hover:scale-105'
                }`}
              >
                {loading ? 'Processing...' : 'Complete Shipment'}
              </button>
            </div>
          </section>
        </main>
        <footer className="bg-gray-900 text-white py-10 mt-auto">
          <div className="container mx-auto px-6">
            <div className="flex flex-col md:flex-row justify-between items-center space-y-6 md:space-y-0">
              <div className="flex items-center space-x-4 flex-shrink-0">
                <img
                  alt="BooksAreReborn Logo"
                  src={image4}
                  className="h-14 w-auto"
                />
                <span className="text-xl font-bold tracking-wide">BooksAreReborn</span>
              </div>
              <div className="flex flex-wrap justify-center space-x-4 md:space-x-8 text-center md:text-left">
                <Link
                  to="/privacy-policy"
                  className="text-sm hover:text-gray-400 transition duration-200"
                >
                  Privacy Policy
                </Link>
                <Link
                  to="/terms-of-use"
                  className="text-sm hover:text-gray-400 transition duration-200"
                >
                  Terms of Use
                </Link>
                <Link
                  to="/acceptable-use"
                  className="text-sm hover:text-gray-400 transition duration-200"
                >
                  Acceptable Use
                </Link>
                <Link
                  to="/contact-us"
                  className="text-sm hover:text-gray-400 transition duration-200"
                >
                  Contact Us
                </Link>
                <Link
                  to="/buyback-guide"
                  className="text-sm hover:text-gray-400 transition duration-200"
                >
                  Buyback Guide
                </Link>
                <Link
                  to="/faq"
                  className="text-sm hover:text-gray-400 transition duration-200"
                >
                  FAQs
                </Link>
              </div>
            </div>
            <div className="border-t border-gray-700 mt-8 pt-4">
              <div className="text-center text-gray-500 text-xs">
                &copy; {new Date().getFullYear()} BooksAreReborn. All rights reserved.
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  };

  export default ReviewShipment;
