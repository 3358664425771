import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Dialog, DialogPanel } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import image4 from '../assets/images/image4.png'; // Import the logo image
import ClipLoader from 'react-spinners/ClipLoader';
import { FiUser, FiHome, FiMapPin, FiPhone, FiMail } from 'react-icons/fi';
import { motion } from 'framer-motion';
import { FiDollarSign } from 'react-icons/fi';


  const stateMap: { [key: string]: string } = {
    'Alabama': 'AL', 'Arizona': 'AZ', 'Arkansas': 'AR', 'California': 'CA', 'Colorado': 'CO',
    'Connecticut': 'CT', 'Delaware': 'DE', 'Florida': 'FL', 'Georgia': 'GA', 'Idaho': 'ID',
    'Illinois': 'IL', 'Indiana': 'IN', 'Iowa': 'IA', 'Kansas': 'KS', 'Kentucky': 'KY',
    'Louisiana': 'LA', 'Maine': 'ME', 'Maryland': 'MD', 'Massachusetts': 'MA', 'Michigan': 'MI',
    'Minnesota': 'MN', 'Mississippi': 'MS', 'Missouri': 'MO', 'Montana': 'MT', 'Nebraska': 'NE',
    'Nevada': 'NV', 'New Hampshire': 'NH', 'New Jersey': 'NJ', 'New Mexico': 'NM', 'New York': 'NY',
    'North Carolina': 'NC', 'North Dakota': 'ND', 'Ohio': 'OH', 'Oklahoma': 'OK', 'Oregon': 'OR',
    'Pennsylvania': 'PA', 'Rhode Island': 'RI', 'South Carolina': 'SC', 'South Dakota': 'SD',
    'Tennessee': 'TN', 'Texas': 'TX', 'Utah': 'UT', 'Vermont': 'VT', 'Virginia': 'VA',
    'Washington': 'WA', 'West Virginia': 'WV', 'Wisconsin': 'WI', 'Wyoming': 'WY'
  };

interface CartItem {
  totalEarnings: number;
  itemName: string;
  isbn: string;
  quantity: number;
  imageLink: string;
  initialEarnings: number;
  dimensions: {
    length: number;
    width: number;
    height: number;
  };
  weight: number;
}

interface Order {
  shipment_id: string;
  order_id: string;
  label_url: string;
  tracking_url: string;
  tracking_code: string;
  est_delivery_date: string;
  status: string;
  email: string;
  total_values: number;
  total_quantities: number;
  items: CartItem[];
  dateCreated: string;  // Add this line 
}

interface FormData {
  name: string;
  street1: string;
  street2: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  paymentMethod: string;
  paypalEmail: string;
}

const AddressForm: React.FC<{
  formData: FormData;
  handleChange: (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => void;
  handleSave: () => void;
  isSaving: boolean;
}> = ({ formData, handleChange, handleSave, isSaving }) => {
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  // Mapping of state names to their abbreviations


  // Handles typing in the state field and maps the abbreviation to the full state name
  const handleStateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
  
    // Find if the value is an abbreviation or full name and map it to the correct state
    const stateFullName = Object.keys(stateMap).find(
      (state) => state.toLowerCase() === value.toLowerCase() || stateMap[state] === value.toUpperCase()
    );
  
    handleChange(Object.assign({}, e, {
      target: Object.assign({}, e.target, {
        name: 'state',
        value: stateFullName || value,
      })
    }));    
  };
  
  const validate = () => {
    const newErrors: any = {};
  
    // Name validation: only letters and spaces allowed
    if (!formData.name) {
      newErrors.name = 'Name is required';
    } else if (!/^[a-zA-Z\s]+$/.test(formData.name)) {
      newErrors.name = 'Please enter a valid name (letters and spaces only)';
    }
  
    // Street1 validation
    if (!formData.street1) newErrors.street1 = 'Street 1 is required';
  
    // City validation: only letters and spaces allowed
    if (!formData.city) {
      newErrors.city = 'City is required';
    } else if (!/^[a-zA-Z\s]+$/.test(formData.city)) {
      newErrors.city = 'Please enter a valid city (letters and spaces only)';
    }
  
    // State validation: must be one of the predefined options
    if (!formData.state || !stateMap[formData.state]) {
      newErrors.state = 'Please select a valid state from the list';
    }
  
    // Zip validation: must be 5 digits
    if (!formData.zip) {
      newErrors.zip = 'Zip is required';
    } else if (!/^\d{5}$/.test(formData.zip)) {
      newErrors.zip = 'Please enter a valid 5-digit zip code';
    }
  
    // Phone validation: allow dashes (e.g., 123-456-7890 or 1234567890)
    if (!formData.phone) {
      newErrors.phone = 'Phone number is required';
    } else if (!/^\d{3}-?\d{3}-?\d{4}$/.test(formData.phone)) {
      newErrors.phone = 'Please enter a valid 10-digit phone number (with or without dashes)';
    }
  
    // PayPal email validation
    if (formData.paymentMethod === 'PayPal' && !formData.paypalEmail) {
      newErrors.paypalEmail = 'PayPal email is required';
    }
  
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  
  const handleSubmit = () => {
    if (validate()) {
      handleSave();
    }
  };

  return (
    <section className="bg-gradient-to-br from-[#ffffff] via-[#ffecd2] to-[#ffffff] p-12 rounded-2xl shadow-2xl max-w-4xl mx-auto mb-12 border border-gray-300">
      <h1 className="text-6xl font-bold text-center mb-10 text-gray-800 tracking-tighter">
        Profile Details
      </h1>
          <form className="space-y-8" noValidate>
      {[
        { label: 'Name', name: 'name', type: 'text', icon: FiUser, placeholder: 'John Doe' },
        { label: 'Street 1', name: 'street1', type: 'text', icon: FiHome, placeholder: '123 Main St' },
        { label: 'Street 2', name: 'street2', type: 'text', icon: FiHome, placeholder: 'Apt, Suite, etc.' },
        { label: 'City', name: 'city', type: 'text', icon: FiMapPin, placeholder: 'City' },
        { label: 'Zip', name: 'zip', type: 'text', icon: FiMapPin, placeholder: 'Zip Code' },
        { label: 'Phone', name: 'phone', type: 'tel', icon: FiPhone, placeholder: '123-456-7890' }
      ].map((field) => (
        <div key={field.name} className="grid grid-cols-1 md:grid-cols-2 gap-6 items-center">
          <label className="block text-lg font-semibold text-gray-700" htmlFor={field.name}>
            {field.label}
          </label>
          <div className="relative">
            <field.icon className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400 transition-transform duration-300 group-hover:scale-110 group-hover:text-green-600" />
            <input
              id={field.name}
              type={field.type}
              name={field.name}
              value={(formData as any)[field.name]}
              onChange={handleChange}
              className={`pl-12 pr-4 py-4 w-full border ${errors[field.name] ? 'border-red-500' : 'border-gray-300'} 
              rounded-xl shadow-md text-gray-800 focus:ring-2 focus:ring-green-600 focus:border-green-600 transition-transform duration-300 ease-in-out hover:scale-105`}
              placeholder={field.placeholder}
              aria-invalid={!!errors[field.name]}
            />
            {errors[field.name] && <span className="text-red-500 text-sm transition-all duration-300 ease-in-out">{errors[field.name]}</span>}
          </div>
        </div>
      ))}

        {/* State Input with Select */} 
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 items-center">
          <label className="block text-lg font-semibold text-gray-700" htmlFor="state">
            State
          </label>
          <div className="relative">
            <select
                id="state"
                name="state"
                value={formData.state}
                onChange={handleStateChange} // Now using the correct handler
                className={`pl-4 pr-12 py-4 w-full border ${errors.state ? 'border-red-500' : 'border-gray-300'} 
                rounded-xl shadow-md text-gray-800 focus:ring-2 focus:ring-green-600 focus:border-green-600 transition-transform duration-300 ease-in-out hover:scale-105`}
                aria-invalid={!!errors.state}
              >
              <option value="" disabled>Select a state</option>
              {Object.keys(stateMap).map((state) => (
                <option key={state} value={state}>
                  {state} ({stateMap[state]})
                </option>
              ))}
            </select>
            {errors.state && <span className="text-red-500 text-sm transition-all duration-300 ease-in-out">{errors.state}</span>}
          </div>
        </div>
              {/* Payment Method Select */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 items-center">
        <label className="block text-lg font-semibold text-gray-700" htmlFor="paymentMethod">
          Payment Method
        </label>
        <div className="relative">
          <FiDollarSign className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400" />
          <select
            id="paymentMethod"
            name="paymentMethod"
            value={formData.paymentMethod}
            onChange={handleChange}
            className={`pl-12 pr-12 py-4 w-full border ${errors.paymentMethod ? 'border-red-500' : 'border-gray-300'} 
            rounded-xl shadow-md text-gray-800 focus:ring-2 focus:ring-green-600 focus:border-green-600 transition-transform duration-300 ease-in-out hover:scale-105`}
          >
            <option value="" disabled>Select</option>
            <option value="Check">Check</option>
            <option value="PayPal">PayPal</option>
          </select>
          {errors.paymentMethod && <span className="text-red-500 text-sm">{errors.paymentMethod}</span>}
        </div>
      </div>

      {/* PayPal Email Field */}
      {formData.paymentMethod === 'PayPal' && (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 items-center">
          <label className="block text-lg font-semibold text-gray-700">PayPal Email</label>
          <div className="relative">
            <FiMail className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <input
              type="email"
              name="paypalEmail"
              value={formData.paypalEmail}
              onChange={handleChange}
              className={`pl-12 pr-4 py-4 w-full border ${errors.paypalEmail ? 'border-red-500' : 'border-gray-300'} 
              rounded-xl shadow-md text-gray-800 focus:ring-2 focus:ring-green-600 focus:border-green-600 transition-all duration-300 ease-in-out hover:scale-105`}
              required
            />
            {errors.paypalEmail && <span className="text-red-500 text-sm">{errors.paypalEmail}</span>}
          </div>
        </div>
      )}

      {/* Submit Button */}
      <button
        type="button"
        onClick={handleSubmit}
        className={`w-full py-4 bg-gradient-to-r from-green-400 to-green-600 text-white font-bold rounded-lg shadow-2xl hover:scale-105 transition-transform duration-300 ease-in-out flex justify-center items-center 
        ${isSaving && 'cursor-not-allowed opacity-50'}`}
        disabled={isSaving}
      >
        {isSaving ? (
          <>
            <ClipLoader color="#ffffff" size={20} className="mr-2" />
            Saving...
          </>
        ) : (
          'Save Address'
        )}
      </button>
    </form>

</section>

  );
};


const OrdersTable: React.FC<{
  orders: Order[];
  handleCancelShipment: (shipmentId: string) => void;
  handlePrint: (labelUrl: string) => void;  // Add handlePrint prop
  loading: boolean; // Pass loading state for canceling
}> = ({ orders, handleCancelShipment, handlePrint, loading }) => (  <section className="bg-white p-6 rounded-lg shadow-lg w-full">
    <div className="flex items-center justify-between mb-6">
      <h2 className="text-2xl font-bold text-gray-800">Your Orders</h2>
      <span className="px-4 py-2 text-sm text-blue-600 bg-blue-100 rounded-full">{orders.length} orders</span>
    </div>

    <div className="space-y-8">
      {orders.length > 0 ? (
        orders.map((order, index) => {
          const shipmentSuccessUrl = `/shipment_success?label_url=${encodeURIComponent(order.label_url)}&tracking_url=${encodeURIComponent(order.tracking_url)}`;

          return (
            <div key={index} className={`p-6 rounded-lg shadow-md transition-transform transform hover:scale-105 ${order.status === 'cancelled' ? 'bg-red-100 border-l-4 border-red-500' : 'bg-white border-l-4 border-green-500'}`}>
              <div className="flex justify-between items-center mb-4">
                <div className="text-xl font-bold text-gray-800">Order ID: <span className="text-blue-500">{order.order_id}</span></div>
                <div className={`text-sm font-medium ${order.status === 'cancelled' ? 'text-red-600' : 'text-green-600'}`}>Status: {order.status}</div>
              </div>

              <div className="mb-4">
                <strong className="block text-lg text-gray-800 mb-2">Items:</strong>
                <ul className="list-disc pl-6 space-y-1 text-gray-600">
                  {(order.items ?? []).map((item, idx) => (
                    <li key={idx}>{item.itemName} <span className="text-gray-500">(ISBN: {item.isbn})</span></li>
                  ))}
                </ul>
              </div>

              <div className="flex justify-between items-center mb-4 text-sm text-gray-700">
                <div>
                  <strong>Quantity:</strong> {order.total_quantities ?? 0}
                </div>
                <div>
                  <strong>Total Value:</strong> ${(order.total_values ?? 0).toFixed(2)}
                </div>
              </div>

              <div className="bg-gray-100 p-3 rounded-md mb-4">
                <div className="text-sm text-gray-700">
                  <strong>Order Date:</strong> {order.dateCreated ? new Date(order.dateCreated).toLocaleString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: true,
                  }) : 'Date not available'}
                </div>
              </div>

              <div className="flex justify-between items-center mb-4">
                <div>
                  <strong className="block text-gray-700">Tracking Number:</strong>
                  <a
                    href={order.tracking_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 hover:underline"
                  >
                    {order.tracking_code}
                  </a>
                </div>
                <div className="text-sm">
                  <strong className="block text-gray-700">Shipping Label:</strong>
                  {order.status !== 'cancelled' ? (
                    <button
                      onClick={() => handlePrint(order.label_url)}
                      className="text-blue-500 hover:underline"
                    >
                      View Label
                    </button>
                  ) : (
                    <span className="text-gray-400">Label unavailable for cancelled orders</span>
                  )}
                </div>
              </div>
              <div className="flex justify-between items-center">
                  {order.status !== 'cancelled' ? (
                    <Link to={shipmentSuccessUrl} className="text-green-600 hover:text-green-800 font-medium">
                      View Shipment Success Page
                    </Link>
                  ) : (
                    <span className="text-gray-400">Shipment Success Page unavailable for cancelled orders</span>
                  )}
                {(order.status === 'created' || order.status === 'unknown') && (
                  <button
                    onClick={() => handleCancelShipment(order.shipment_id)}
                    className="bg-red-500 text-white px-5 py-2 rounded-full shadow-lg hover:bg-red-600 transition duration-300 ease-in-out flex items-center"
                    disabled={loading}
                  >
                    {loading ? (
                      <>
                        <ClipLoader color="#ffffff" size={20} className="mr-2" />
                        Processing...
                      </>
                    ) : (
                      'Cancel Shipment'
                    )}
                  </button>
                )}
              </div>
            </div>
          );
        })
      ) : (
        <div className="text-center text-gray-600">No orders found.</div>
      )}
    </div>
  </section>
);


const YourAccount: React.FC = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false); // State for mobile menu
  const [formData, setFormData] = useState<FormData>({
    name: '',
    street1: '',
    street2: '',
    city: '',
    state: '',
    zip: '',
    phone: '',
    paymentMethod: '',
    paypalEmail: ''
  });
  const [orders, setOrders] = useState<Order[]>([]);
  const [isSaving, setIsSaving] = useState(false); // State for saving address
  const [loading, setLoading] = useState(false); // State for canceling shipment
  const { currentUser, logout } = useAuth();  
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');  // Error message to provide feedback
  const [successMessage, setSuccessMessage] = useState('');  // State for success message


  useEffect(() => {
    const fetchSavedAddress = async () => {
      if (currentUser) {
        const addressRef = doc(db, 'addresses', currentUser.uid);
        const addressDoc = await getDoc(addressRef);
        if (addressDoc.exists()) {
          setFormData(addressDoc.data() as FormData);
        }
      }
    };
    fetchSavedAddress();
  }, [currentUser]);

  useEffect(() => {
    const fetchOrders = async () => {
      if (currentUser) {
        try {
          const response = await axios.get(`https://resellerapp-backend.azurewebsites.net/get_orders?user_id=${currentUser.uid}`);
          
          const ordersWithCamelCase: Order[] = response.data.orders.map((order: any) =>
            Object.assign({}, order, {
              dateCreated: order.date_created  // Map date_created to dateCreated
            })
          );
          
          
  
          setOrders(ordersWithCamelCase);
        } catch (error) {
          console.error('Error fetching orders:', error);
        }
      }
    };
    
    fetchOrders();
  }, [currentUser]);
  

  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
    setFormData(prevFormData => 
      Object.assign({}, prevFormData, {
        [e.target.name]: e.target.value
      })
    );
  };
  

  const handleSave = async () => {
    if (!formData.paymentMethod || formData.paymentMethod === '') {
      setErrorMessage('Please select a payment method.');
      return;
    }
  
    if (formData.paymentMethod === 'PayPal' && !formData.paypalEmail) {
      setErrorMessage('Please enter your PayPal email address.');
      return;
    }
  
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (formData.paymentMethod === 'PayPal' && !emailPattern.test(formData.paypalEmail)) {
      setErrorMessage('Please enter a valid PayPal email address.');
      return;
    }
  
    // Ensure that the state is a valid option
    if (!stateMap[formData.state]) {
      setErrorMessage('Please select a valid state.');
      return;
    }
  
    if (currentUser) {
      setIsSaving(true);
      try {
        const addressRef = doc(db, 'addresses', currentUser.uid);
        await setDoc(addressRef, formData);
        setSuccessMessage('Address saved successfully!');  // Show success message
      } catch (error) {
        console.error('Error saving address:', error);
        setErrorMessage('Failed to save address. Please try again.');
      } finally {
        setIsSaving(false);
      }
    } else {
      setErrorMessage('You need to be logged in to save your address.');
    }
  };

  const handleCancelShipment = async (shipmentId: string) => {
    if (!currentUser) {
      setErrorMessage('You need to be logged in to cancel a shipment.');
      return;
    }

    setLoading(true);
    try {
      await axios.post('https://resellerapp-backend.azurewebsites.net/cancel_shipment', {
        shipment_id: shipmentId,
        user_id: currentUser.uid,
      });

      setOrders((prevOrders) =>
        prevOrders.map((order) =>
          order.shipment_id === shipmentId
            ? Object.assign({}, order, { status: 'cancelled' })
            : order
        )
      );
      
    } catch (error) {
      console.error('Error cancelling shipment:', error);
      setErrorMessage('Failed to cancel shipment. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  const handlePrint = (labelUrl: string) => {
    navigate('/print-shipping-label', { state: { labelUrl } });
  };
  

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error('Failed to log out', error);
      setErrorMessage('Failed to log out. Please try again.');
    }
  };

  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-b from-[#f5e4c3] via-[#cfd9df] to-[#121212]">
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-b from-[#f5e4c3] via-[#cfd9df] to-[#121212]">
      {/* Header */}
      <header className="w-full bg-[#f5deb3] shadow-md p-4 fixed top-0 left-0 flex justify-between items-center z-50 border-b border-gray-200">
        <div className="flex items-center justify-center flex-1">
          <Link to="/" className="text-2xl font-bold text-gray-900 flex items-center space-x-2">
            <img
              alt="BooksAreReborn Logo"
              src={image4}
              className="h-12 w-auto" // Adjusted logo size for the header
            />
            <span>BooksAreReborn</span>
          </Link>
          <nav className="hidden lg:flex space-x-4 mx-auto">
            <Link to="/" className="text-gray-900 hover:text-white hover:bg-gray-700 transition-colors duration-300 px-3 py-2 rounded-md text-lg font-medium">
              Home
            </Link>
            <Link to="/get-offer" className="text-gray-900 hover:text-white hover:bg-gray-700 transition-colors duration-300 px-3 py-2 rounded-md text-lg font-medium">
              Get Offer
            </Link>
          </nav>
        </div>
        <div className="flex items-center space-x-4">
          <button
            onClick={handleLogout}
            className="hidden lg:block bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded-full shadow transition"
          >
            Log Out
          </button>
          <button
            type="button"
            onClick={() => setMobileMenuOpen(true)}
            className="lg:hidden p-2 rounded-full bg-gray-900 text-white"
          >
            <Bars3Icon aria-hidden="true" className="h-6 w-6" />
          </button>
        </div>
      </header>

      <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
        <div className="fixed inset-0 z-50" />
        <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-[#f5deb3] px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <Link to="/" className="-m-1.5 p-1.5 flex items-center space-x-2">
              <img
                alt="BooksAreReborn Logo"
                src={image4}
                className="h-12 w-auto" // Adjusted logo size for the mobile menu
              />
            </Link>
            <button
              type="button"
              onClick={() => setMobileMenuOpen(false)}
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
            >
              <XMarkIcon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <Link
                  to="/"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Home
                </Link>
                <Link
                  to="/get-offer"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Get Offer
                </Link>
              </div>
              <div className="py-6">
                <button
                  onClick={handleLogout}
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Log Out
                </button>
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>

      {/* Main Content */}
      <main className="pt-24 w-full max-w-4xl px-4">
          <AddressForm formData={formData} handleChange={handleChange} handleSave={handleSave} isSaving={isSaving} />
                      {errorMessage && (
              <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                transition={{ duration: 0.3 }}
                className="flex items-center justify-between max-w-lg mx-auto mb-6 p-4 bg-red-100 border border-red-400 text-red-700 rounded-lg shadow-md"
                role="setErrorMessage"
              >
                <div className="flex items-center space-x-2">
                  {/* Icon for error */}
                  <svg
                    className="w-6 h-6 text-red-700"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M18.364 5.636a9 9 0 11-12.728 0M12 9v4m0 4h.01"
                    ></path>
                  </svg>
                  <span className="font-semibold">{errorMessage}</span>
                </div>
                <button
                  onClick={() => setErrorMessage('')}
                  className="text-red-500 hover:text-red-800 transition-colors duration-200"
                >
                  <XMarkIcon className="w-5 h-5" />
                </button>
              </motion.div>
            )}
        {/* Success Alert */}
        {successMessage && (
                  <motion.div
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    transition={{ duration: 0.3 }}
                    className="flex items-center justify-between max-w-lg mx-auto mb-6 p-4 bg-green-100 border border-green-400 text-green-700 rounded-lg shadow-md"
                    role="alert"
                  >
                    <div className="flex items-center space-x-2">
                      <svg
                        className="w-6 h-6 text-green-700"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 13l4 4L19 7"
                        ></path>
                      </svg>
                      <span className="font-semibold">{successMessage}</span>
                    </div>
                    <button
                      onClick={() => setSuccessMessage('')}
                      className="text-green-500 hover:text-green-800 transition-colors duration-200"
                    >
                      <XMarkIcon className="w-5 h-5" />
                    </button>
                  </motion.div>
                )}
          <OrdersTable orders={orders} handleCancelShipment={handleCancelShipment} handlePrint={handlePrint} loading={loading} />
        </main>
      <br />
      </div>
      <footer className="bg-gray-900 text-white py-10">
  <div className="container mx-auto px-6">
    <div className="flex flex-col md:flex-row justify-between items-center space-y-6 md:space-y-0">
      <div className="flex items-center space-x-4 flex-shrink-0">
        <img
          alt="BooksAreReborn Logo"
          src={image4}
          className="h-14 w-auto"
        />
        <span className="text-xl font-bold tracking-wide">BooksAreReborn</span>
      </div>
      <div className="flex flex-wrap justify-center space-x-4 md:space-x-8 text-center md:text-left">
        <Link
          to="/privacy-policy"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          Privacy Policy
        </Link>
        <Link
          to="/terms-of-use"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          Terms of Use
        </Link>
        <Link
          to="/acceptable-use"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          Acceptable Use
        </Link>
        <Link
          to="/contact-us"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          Contact Us
        </Link>
        <Link
          to="/buyback-guide"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          Buyback Guide
        </Link>
        <Link
          to="/faq"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          FAQs
        </Link>
      </div>
    </div>
    <div className="border-t border-gray-700 mt-8 pt-4">
      <div className="text-center text-gray-500 text-xs">
        &copy; {new Date().getFullYear()} BooksAreReborn. All rights reserved.
      </div>
    </div>
  </div>
</footer>
      </div>
    );
  };

export default YourAccount;