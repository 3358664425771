import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Dialog, DialogPanel } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { useAuth } from '../contexts/AuthContext';
import image4 from '../assets/images/image4.png';

const BuyBackGuidePage: React.FC = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error('Failed to log out', error);
    }
  };

  const handleSignUpClick = () => {
    navigate('/auth', { state: { isLogin: false } });
  };

  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-b from-[#f5e4c3] via-[#cfd9df] to-[#121212]">
      <header className="w-full bg-[#f5deb3] shadow-md p-4 fixed top-0 left-0 flex justify-between items-center z-50 border-b border-gray-200">
        <div className="flex items-center justify-center flex-1">
          <Link to="/" className="text-2xl font-bold text-gray-900 flex items-center space-x-2">
            <img
              alt="BooksAreReborn Logo"
              src={image4}
              className="h-12 w-auto"
            />
            <span>BooksAreReborn</span>
          </Link>
          <nav className="hidden lg:flex space-x-4 mx-auto">
            <Link to="/" className="text-gray-900 hover:text-white hover:bg-gray-700 transition-colors duration-300 px-3 py-2 rounded-md text-lg font-medium">
              Home
            </Link>
            <Link to="/your-account" className="text-gray-900 hover:text-white hover:bg-gray-700 transition-colors duration-300 px-3 py-2 rounded-md text-lg font-medium">
              Your Account
            </Link>
            <Link to="/get-offer" className="text-gray-900 hover:text-white hover:bg-gray-700 transition-colors duration-300 px-3 py-2 rounded-md text-lg font-medium">
              Cart
            </Link>
          </nav>
        </div>
        <div className="flex items-center space-x-4">
          {currentUser ? (
            <button
              onClick={handleLogout}
              className="hidden lg:block bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded-full shadow transition"
            >
              Log out
            </button>
          ) : (
            <>
              <button
                onClick={() => navigate('/auth')}
                className="hidden lg:block bg-green-600 hover:bg-green-700 text-white px-4 py-2 rounded-full shadow transition"
              >
                Sign In
              </button>
              <button
                onClick={handleSignUpClick}
                className="hidden lg:block bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-full shadow transition"
              >
                Sign Up
              </button>
            </>
          )}
          <button
            type="button"
            onClick={() => setMobileMenuOpen(true)}
            className="lg:hidden p-2 rounded-full bg-gray-900 text-white"
          >
            <Bars3Icon aria-hidden="true" className="h-6 w-6" />
          </button>
        </div>
      </header>

      <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
        <div className="fixed inset-0 z-50" />
        <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-[#f5deb3] px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <Link to="/" className="-m-1.5 p-1.5 flex items-center space-x-2">
              <img
                alt="BooksAreReborn Logo"
                src={image4}
                className="h-12 w-auto"
              />
            </Link>
            <button
              type="button"
              onClick={() => setMobileMenuOpen(false)}
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
            >
              <XMarkIcon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <Link
                  to="/"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Home
                </Link>
                <Link
                  to="/your-account"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Your Account
                </Link>
                <Link
                  to="/get-offer"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Cart
                </Link>
              </div>
              <div className="py-6">
                {currentUser ? (
                  <button
                    onClick={handleLogout}
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Log out
                  </button>
                ) : (
                  <>
                    <button
                      onClick={() => navigate('/auth')}
                      className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      Sign In
                    </button>
                    <button
                      onClick={handleSignUpClick}
                      className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      Sign Up
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>

      <main className="flex-grow pt-24 flex justify-center items-center">
        <section className="w-full max-w-3xl bg-white p-6 rounded-lg shadow-lg text-center">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">Buyback Condition Guide</h1>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">1. Things You Need to Pay Attention To:</h2>
          <p className="text-gray-700 mb-4">
            Before sending us your books, please ensure they meet our acceptance criteria and condition guidelines. Pay attention to the type of cover, presence of supplementary materials like CDs/DVDs, access codes, and multiple volumes.
          </p>

          <h3 className="text-xl font-bold text-gray-900 mb-2">Loose Leaf Editions</h3>
          <p className="text-gray-700 mb-4">
            Loose leaf editions may have ISBNs of hardcovers or paperbacks. Ensure you locate the correct ISBN on the copyright page or back cover. Custom-made editions without an ISBN cannot be accepted.
          </p>

          <h3 className="text-xl font-bold text-gray-900 mb-2">Multiple Volume Sets</h3>
          <p className="text-gray-700 mb-4">
            If your book corresponds to a set, send the entire set. We cannot accept individual volumes from a set without a separate ISBN.
          </p>

          <h3 className="text-xl font-bold text-gray-900 mb-2">Stickers and Tape</h3>
          <p className="text-gray-700 mb-4">
            Remove all stickers and tape before sending. Stickers may conceal important information such as the ISBN or markings indicating the item is an exam copy, which we do not accept.
          </p>

          <h3 className="text-xl font-bold text-red-600 mb-2">Warning: Stickers on Textbooks!</h3>
          <p className="text-gray-700 mb-4">
            Stickers and tape on textbooks often hide important details, such as:
          </p>
          <ul className="text-gray-700 mb-4 list-disc list-inside">
            <li>Different ISBN: Sometimes, the ISBN under the sticker corresponds to a different version, like a standalone book instead of a bundle.</li>
            <li>Instructor’s Editions: Stickers can cover markings indicating that a book is an Instructor's or Teacher's edition, which we cannot accept.</li>
            <li>Complimentary Copies: Stickers may hide markings like "Complimentary Copy," "Examination Copy," "Not for Resale," which are not accepted.</li>
          </ul>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">2. Items We Do Not Accept for Buyback:</h2>
          <ul className="text-gray-700 mb-4 list-disc list-inside">
            <li>Instructor's, teacher's, or professional editions</li>
            <li>Rebound books</li>
            <li>Complimentary/free copies, sample editions, and/or exam copies</li>
            <li>International editions (may only be accepted at a fraction of a price of a US edition)</li>
            <li>Custom editions for a specific college or area</li>
            <li>Exam, promotional, or complimentary copies; marked "not for resale," "not for sale," "preliminary edition," or "uncorrected proof"</li>
            <li>Used, opened, visible, or scratched access codes</li>
            <li>Items without any ISBN</li>
            <li>Workbooks or study guides with any markings or filled-in answers</li>
            <li>Books with excessive highlighting or markings on more than 2–3 pages</li>
            <li>Books with any signs of water damage, including wrinkled or curled pages, stains, yellowing, mold, and color change</li>
            <li>Books with ripped, missing, or falling out pages or cover</li>
            <li>Books with heavy wear, including damaged or detached binding, torn pages or cover, or general unacceptable condition</li>
            <li>Counterfeit books</li>
            <li>No rips, tears, stains, or water damage</li>
            <li>No writing or highlighting (except textbooks, author sign, or gift notes on first pages)</li>
            <li>No pages falling out or coming loose, and no broken spines/bindings</li>
            <li>No ex-library books</li>
            <li>All hardcovers originally sold with a dust jacket must include the dust jacket</li>
          </ul>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">3. Inauthentic Books Policy</h2>
          <p className="text-gray-700 mb-4">
            We take counterfeit textbooks seriously. Each item received is inspected thoroughly. Counterfeit books are unauthorized copies or imitations of original copyrighted books. If a book is determined to be counterfeit, no payment will be issued, and the item will not be returned. Instead, it may be forwarded to the relevant publisher for investigation and potential disposal. Accounts involved in submitting counterfeit books may be disabled without the possibility of reactivation.
          </p>

          <p className="text-gray-700 mb-4">
            If you have any doubts concerning the condition or ISBNs of your textbooks, please feel free to <Link to="/contact-us" className="text-blue-500 hover:underline">Contact Us</Link> page or send pictures of your book before shipping.
          </p>


          <p className="text-gray-700 text-sm">Updated on April 1st, 2024</p>
        </section>
      </main>
      <br />
      <footer className="bg-gray-900 text-white py-10">
  <div className="container mx-auto px-6">
    <div className="flex flex-col md:flex-row justify-between items-center space-y-6 md:space-y-0">
      <div className="flex items-center space-x-4 flex-shrink-0">
        <img
          alt="BooksAreReborn Logo"
          src={image4}
          className="h-14 w-auto"
        />
        <span className="text-xl font-bold tracking-wide">BooksAreReborn</span>
      </div>
      <div className="flex flex-wrap justify-center space-x-4 md:space-x-8 text-center md:text-left">
        <Link
          to="/privacy-policy"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          Privacy Policy
        </Link>
        <Link
          to="/terms-of-use"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          Terms of Use
        </Link>
        <Link
          to="/acceptable-use"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          Acceptable Use
        </Link>
        <Link
          to="/contact-us"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          Contact Us
        </Link>
        <Link
          to="/buyback-guide"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          Buyback Guide
        </Link>
        <Link
          to="/faq"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          FAQs
        </Link>
      </div>
    </div>
    <div className="border-t border-gray-700 mt-8 pt-4">
      <div className="text-center text-gray-500 text-xs">
        &copy; {new Date().getFullYear()} BooksAreReborn. All rights reserved.
      </div>
    </div>
  </div>
</footer>
    </div>
  );
};

export default BuyBackGuidePage;
