import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { db, rtdb } from '../firebase';
import { ref, update } from 'firebase/database';
import { Dialog, DialogPanel } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import image4 from '../assets/images/image4.png'; // Import the logo image
import debounce from 'lodash/debounce'; // Import lodash debounce or implement your own debounce function
import { ClipLoader } from 'react-spinners'; // Import the spinner loader for feedback
import { motion } from 'framer-motion';
import ga from '../ga-init'; // Import GA tracking module
import ConfirmationModal from './ConfirmationModal';




interface CartItem {
  totalEarnings: number;
  itemName: string;
  isbn: string;
  quantity: number;
  imageLink: string;
  initialEarnings: number;
  dimensions: {
    length: number;
    width: number;
    height: number;
  };
  weight: number;
}

const Getoffer: React.FC = () => {
  const [isbn, setIsbn] = useState('');
  const [quantity, setQuantity] = useState<number | string>(1);
  const [shoppingCart, setShoppingCart] = useState<CartItem[]>([]);
  const [totalValues, setTotalValues] = useState(0);
  const [totalQuantities, setTotalQuantities] = useState(0);
  const [totalLength, setTotalLength] = useState(0);
  const [totalWidth, setTotalWidth] = useState(0);
  const [totalHeight, setTotalHeight] = useState(0);
  const [totalWeight, setTotalWeight] = useState(0);
  const [formData, setFormData] = useState<any>({});
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');  // Error message to provide feedback
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [modalAction, setModalAction] = useState<(() => void) | null>(null);
  
  const [loading, setLoading] = useState(false);  // For spinner feedback



  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();

  // Track adding items to the cart
  const trackAddToCart = ga.trackEventBuilder('Cart');
  const trackShipmentCreation = ga.trackEventBuilder('Shipment');

  // Check for cookie consent
const isAnalyticsEnabled = () => {
  return localStorage.getItem('cookieConsent') === 'accepted';
};

  useEffect(() => {
    const fetchCart = async () => {
      let mergedCart: CartItem[] = [];

      if (currentUser) {
        const cartRef = doc(db, 'carts', currentUser.uid);
        const cartDoc = await getDoc(cartRef);

        if (cartDoc.exists()) {
          const cartData = cartDoc.data() as { shopping_cart: CartItem[], totalValues: number, totalQuantities: number };
          mergedCart = cartData.shopping_cart || [];
          setTotalValues(cartData.totalValues || 0);
          setTotalQuantities(cartData.totalQuantities || 0);
        }
      }

      const localCartData = localStorage.getItem('shoppingCart');
      if (localCartData) {
        const localCart = JSON.parse(localCartData) as CartItem[];
        mergedCart = mergeCartItems(mergedCart, localCart);
      }

    // Validate the total earnings after merging the cart
    const totalEarnings = mergedCart.reduce((acc, item) => acc + item.totalEarnings, 0);

    if (totalEarnings > 100) {
      setErrorMessage('Your total cart earnings exceed the $100 limit. Please adjust your cart.');
      mergedCart = mergedCart.filter(item => item.totalEarnings <= 100); // Adjust cart as per your requirement
    }

      setShoppingCart(mergedCart);
      calculateTotals(mergedCart);

      if (currentUser) {
        await saveCartToFirestoreAndRTDB(mergedCart);
        localStorage.removeItem('shoppingCart');
      } else {
        localStorage.setItem('shoppingCart', JSON.stringify(mergedCart));
      }
    };

    fetchCart();
  }, [currentUser]);

  useEffect(() => {
    const pendingIsbn = localStorage.getItem('pendingIsbn');
    if (pendingIsbn) {
      handleAddToCart(pendingIsbn, 1);
      localStorage.removeItem('pendingIsbn');
    }
  }, []);

  useEffect(() => {
    const fetchSavedAddress = async () => {
      if (currentUser) {
        const addressRef = doc(db, 'addresses', currentUser.uid);
        const addressDoc = await getDoc(addressRef);
        if (addressDoc.exists()) {
          setFormData(addressDoc.data() as any);
        }
      }
    };
    fetchSavedAddress();
  }, [currentUser]);

  useEffect(() => {
    calculateTotals(shoppingCart);
  }, [shoppingCart]);


  const convertIsbn10ToIsbn13 = (isbn10: string): string => {
    const isbnWithoutCheckDigit = '978' + isbn10.substring(0, 9);
    const digits = isbnWithoutCheckDigit.split('').map(Number);
  
    // Calculate the check digit for ISBN-13
    const checkDigit = digits.reduce((sum, digit, index) => {
      return sum + digit * (index % 2 === 0 ? 1 : 3);
    }, 0);
    
    const remainder = checkDigit % 10;
    const isbn13CheckDigit = remainder === 0 ? 0 : 10 - remainder;
  
    return isbnWithoutCheckDigit + isbn13CheckDigit;
  };
  

  const formatIsbn = (isbn: string): string => {
    const formattedIsbn = isbn.replace(/-/g, '');
  
    // Convert ISBN-10 to ISBN-13
    if (formattedIsbn.length === 10) {
      return convertIsbn10ToIsbn13(formattedIsbn);
    }
  
    return formattedIsbn;  // ISBN-13 remains as is
  };
  
// Validate ISBN - it must be either 10 digits (ISBN-10) or exactly 13 digits (ISBN-13)
const isValidIsbn = (isbn: string) => {
  const formattedIsbn = formatIsbn(isbn);
  
  // If ISBN starts with 978 or 979, only valid if it's exactly 13 digits
  if (formattedIsbn.startsWith('978') || formattedIsbn.startsWith('979')) {
    return formattedIsbn.length === 13;  // Ensure all 13 digits are entered
  }
  
  // For other cases, treat it as ISBN-10 and ensure it has exactly 10 digits
  return formattedIsbn.length === 10;
};
  // Handle ISBN input change immediately
  const handleIsbnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsbn(e.target.value);
    setErrorMessage('');  // Reset error message on user input
  };

// Debounced function for handling adding the item to the cart (or performing API call)
const debouncedAddToCart = debounce(async (isbn: string, quantity: number) => {
  const formattedIsbn = formatIsbn(isbn);

  // Skip the API call if the ISBN is not valid (less than 10 or 13 characters)
  if (!isValidIsbn(formattedIsbn)) {
    console.log('Incomplete ISBN, waiting for full entry.');
    return;  // Exit if ISBN is not valid yet (incomplete)
  }

  // Proceed with the API call if the ISBN is valid (either 10 or 13 digits)
  await handleAddToCart(formattedIsbn, quantity);
}, 300);  // Debounce delay

// Trigger the debounced function when ISBN or quantity changes
useEffect(() => {
  if (isValidIsbn(isbn)) {
    debouncedAddToCart(isbn, Number(quantity));
  }

  // Cleanup the debounce when unmounting to avoid memory leaks
  return () => {
    debouncedAddToCart.cancel();
  };
}, [isbn, quantity]);

  // Handle quantity change
  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value);
    if (value >= 1) {
      setQuantity(value);
    }
  };

axios.defaults.withCredentials = true;


  // Optimistic UI update and debounced cart adding
  const handleAddToCart = async (isbn: string, quantity: number) => {
    setLoading(true); // Set loading to true for the spinner
    const formattedIsbn = formatIsbn(isbn);

    const filteredCart = shoppingCart.filter(item => item.totalEarnings !== 0);
    setShoppingCart(filteredCart);  // Optimistically update the cart

    const existingItemIndex = filteredCart.findIndex(item => formatIsbn(item.isbn) === formattedIsbn);
    if (existingItemIndex !== -1) {
      setErrorMessage('This item is already added to the cart.');
      setLoading(false); // Stop spinner
      return;
    }
    
    try {
      // Optimistically update the UI before sending the API request
      const optimisticCart = filteredCart.concat({
        isbn: formattedIsbn,
        quantity,
        totalEarnings: 0,  // Placeholder, backend will recalculate
        initialEarnings: 0,  // Placeholder, backend will recalculate
        itemName: 'Fetching details...',  // Placeholder
        imageLink: '',  // Placeholder image
        dimensions: { length: 0, width: 0, height: 0 },
        weight: 0
      });
      setShoppingCart(optimisticCart);
        // Track the event of adding to the cart, only if analytics is enabled
        if (isAnalyticsEnabled()) {
          trackAddToCart({
            action: 'Add to Cart',
            label: formattedIsbn,
            value: quantity,
          });
        }

      // Send request to backend
      const response = await axios.post('https://resellerapp-backend.azurewebsites.net/add_to_cart', 
        { isbn: formattedIsbn, quantity },
        { withCredentials: true }
      );

      const { shopping_cart: newCartItems } = response.data;
      if (!newCartItems || newCartItems.length === 0) {
        console.error('Received empty shopping cart from backend');
        setLoading(false); // Stop spinner
        return;
      }

      const updatedCart = mergeCartItems(filteredCart, newCartItems.map((item: CartItem) => (
        Object.assign({}, item, {
        totalEarnings: calculateEarnings(item.initialEarnings, item.quantity)
      }))));

      setShoppingCart(updatedCart);
      calculateTotals(updatedCart);

      if (currentUser) {
        await saveCartToFirestoreAndRTDB(updatedCart);
      } else {
        localStorage.setItem('shoppingCart', JSON.stringify(updatedCart));
      }

      setLoading(false); // Stop spinner once cart is updated
    } catch (error) {
      console.error('Error adding to cart:', error);
      setLoading(false); // Stop spinner on error
    }
  };


  // Rendering the optimistic UI for shopping cart
  const renderOptimisticCart = () => {
    return (
      <div className="mt-10 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
        {shoppingCart.map((item, index) => (
          <div
            key={index}
            className={`bg-white shadow-md rounded-lg p-6 flex flex-col items-center ${
              item.totalEarnings === 0 ? 'border-l-4 border-yellow-400' : ''
            }`}
          >
            {/* Placeholder spinner if totalEarnings is 0 */}
            {item.totalEarnings === 0 ? (
              <div className="flex justify-center items-center h-24">
                <ClipLoader color="#3498db" size={40} /> {/* Spinner */}
              </div>
            ) : (
              <img
                src={item.imageLink || 'https://via.placeholder.com/150'} // Placeholder image until fetched
                alt={item.itemName}
                className="w-32 h-32 object-cover mb-4 rounded-lg"
              />
            )}
            <h3 className="text-lg font-semibold mb-2">
              {item.itemName || 'Fetching...'}
            </h3>
            <p className="text-sm text-gray-500">{item.isbn}</p>
            <p className="text-sm text-gray-700">Qty: {item.quantity}</p>
            <p className="font-semibold text-gray-900 mt-2">
              {item.totalEarnings > 0 ? `$${item.totalEarnings.toFixed(2)}` : 'Calculating...'}
            </p>
          </div>
        ))}
      </div>
    );
  };  


  const mergeCartItems = (existingCart: CartItem[], newCartItems: CartItem[]): CartItem[] => {
    const updatedCart = existingCart.slice();
  
    newCartItems.forEach((newItem: CartItem) => {
      const formattedIsbn = formatIsbn(newItem.isbn);
      const existingItemIndex = updatedCart.findIndex(item => formatIsbn(item.isbn) === formattedIsbn);
  
      if (existingItemIndex !== -1) {
        // Combine the quantities of the same item
        const combinedQuantity = updatedCart[existingItemIndex].quantity + newItem.quantity;
  
        // Recalculate total earnings with diminishing factor based on combined quantity
        const recalculatedEarnings = calculateEarnings(updatedCart[existingItemIndex].initialEarnings, combinedQuantity);
  
        // Update the existing item with the new quantity and recalculated earnings
        updatedCart[existingItemIndex] = Object.assign({}, updatedCart[existingItemIndex], {

          quantity: combinedQuantity,
          totalEarnings: recalculatedEarnings, // Use the recalculated earnings
        });
      } else {
        // Add new item to the cart if it doesn't already exist
        updatedCart.push(newItem);
      }
    });
  
    return updatedCart;
  };
  
  
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    handleAddToCart(isbn, quantity as number);
  };

  const handleCreateShipment = async () => {
    if (!currentUser) {
      setErrorMessage('You have to sign in if you want to create a shipment.');
      navigate('/auth'); // Redirect to the AuthPage
      return;
    }
  
    // Filter out items with totalEarnings === 0
    const updatedCart = shoppingCart.filter(item => item.totalEarnings !== 0);
  
    if (updatedCart.length === 0) {
      setErrorMessage('Your cart is empty after removing items with zero earnings.');
      return;
    }
  
    // Recalculate totals after filtering the cart
    calculateTotals(updatedCart);
  
    if (totalValues < 6.13) {
      setErrorMessage('You need at least $6.13 in total earnings to proceed to checkout.');
      return;
    }
  
    // Update the state and Firestore/RTDB with the filtered cart
    setShoppingCart(updatedCart);
  
    if (currentUser) {
      await saveCartToFirestoreAndRTDB(updatedCart); // Ensure this completes before proceeding
    } else {
      localStorage.setItem('shoppingCart', JSON.stringify(updatedCart));
    }
      // Track shipment creation, only if analytics is enabled
      if (isAnalyticsEnabled()) {
        trackShipmentCreation({
          action: 'Create Shipment',
          label: 'Shipment created with items',
          value: updatedCart.length,
        });
      }
    // Navigate to the ReviewShipment page
    navigate('/review-shipment', {
      state: {
        shoppingCart: updatedCart,
        formData,
        totalValues,
        totalQuantities,
        totalLength,
        totalWidth,
        totalHeight,
        totalWeight
      }
    });
  };

  const calculateEarnings = (initialEarnings: number, quantity: number): number => {
    const diminishingFactor = Math.sqrt(quantity);
    return initialEarnings * diminishingFactor;
  };

  const calculateTotals = (cart: CartItem[]) => {
    const totalValues = cart.reduce((acc, item) => acc + (item.totalEarnings || 0), 0);
    const totalQuantities = cart.reduce((acc, item) => acc + (item.quantity || 0), 0);
    const totalWeight = cart.reduce((acc, item) => acc + (item.weight * item.quantity || 0), 0);

    const totalDimensions = cart.reduce((acc, item) => {
      const length = item.dimensions && item.dimensions.length ? item.dimensions.length : 0;
      const width = item.dimensions && item.dimensions.width ? item.dimensions.width : 0;
      const height = item.dimensions && item.dimensions.height ? item.dimensions.height : 0;

      acc.length = Math.max(acc.length, length);
      acc.width = Math.max(acc.width, width);
      acc.height += height * (item.quantity || 1);

      return acc;
    }, { length: 0, width: 0, height: 0 });

    setTotalValues(totalValues);
    setTotalQuantities(totalQuantities);
    setTotalLength(totalDimensions.length);
    setTotalWidth(totalDimensions.width);
    setTotalHeight(totalDimensions.height);
    setTotalWeight(totalWeight);
  };

  const deleteItem = (isbn: string) => {
    setModalMessage('Are you sure you want to delete this item?');
    setModalAction(() => async () => {  // Use a callback to delay the action
      const updatedCart = shoppingCart.filter(item => formatIsbn(item.isbn) !== formatIsbn(isbn));
      setShoppingCart(updatedCart);
      calculateTotals(updatedCart);
  
      if (currentUser) {
        try {
          const cartRef = doc(db, 'carts', currentUser.uid);
          await setDoc(cartRef, { shopping_cart: updatedCart });
  
          const cartRtdbRef = ref(rtdb, `carts/${currentUser.uid}`);
          await update(cartRtdbRef, { shopping_cart: updatedCart });
        } catch (error) {
          console.error('Error updating cart in Firestore and Realtime Database:', error);
        }
      } else {
        localStorage.setItem('shoppingCart', JSON.stringify(updatedCart));
      }
    });
    setIsModalOpen(true);
  };

  const increaseQuantity = async (isbn: string) => {
    const updatedCart = shoppingCart.map((item: CartItem) => {
      if (formatIsbn(item.isbn) === formatIsbn(isbn)) {
        const newQuantity = item.quantity + 1;
        const newTotalEarnings = calculateEarnings(item.initialEarnings, newQuantity);
  
        const potentialTotalEarnings = shoppingCart.reduce((acc, cartItem) => {
          return formatIsbn(cartItem.isbn) === formatIsbn(isbn) ? acc + newTotalEarnings : acc + cartItem.totalEarnings;
        }, 0);
  
        if (potentialTotalEarnings > 100) {
          setErrorMessage('Increasing the quantity would exceed the $100 earnings limit. Please adjust your cart.');
          return item;
        }
  
        return Object.assign({}, item, {
          quantity: newQuantity,
          totalEarnings: newTotalEarnings,
        });
        
      }
      return item;
    });
  
    setShoppingCart(updatedCart);
    calculateTotals(updatedCart);
  
    if (currentUser) {
      await saveCartToFirestoreAndRTDB(updatedCart); // Ensure this completes before proceeding
    } else {
      localStorage.setItem('shoppingCart', JSON.stringify(updatedCart));
    }
  };
  

  const decreaseQuantity = (isbn: string) => {
    const updatedCart = shoppingCart.map((item: CartItem) => {
      if (formatIsbn(item.isbn) === formatIsbn(isbn) && item.quantity > 1) {
        item.quantity -= 1;
        item.totalEarnings = calculateEarnings(item.initialEarnings, item.quantity);
      }
      return item;
    });
    setShoppingCart(updatedCart);
    calculateTotals(updatedCart);

    if (currentUser) {
      saveCart();
    } else {
      localStorage.setItem('shoppingCart', JSON.stringify(updatedCart));
    }
  };

  const changeQuantity = (isbn: string, newQuantity: number) => {
    if (newQuantity >= 1) {
      const updatedCart = shoppingCart.map((item: CartItem) => {
        if (formatIsbn(item.isbn) === formatIsbn(isbn)) {
          const newTotalEarnings = calculateEarnings(item.initialEarnings, newQuantity);

          const potentialTotalEarnings = shoppingCart.reduce((acc, cartItem) => {
            return formatIsbn(cartItem.isbn) === formatIsbn(isbn) ? acc + newTotalEarnings : acc + cartItem.totalEarnings;
          }, 0);

          if (potentialTotalEarnings > 100) {
            setErrorMessage('Changing the quantity would exceed the $100 earnings limit. Please adjust your cart.');
            return item;
          }

          return Object.assign({}, item, {
            quantity: newQuantity,
            totalEarnings: newTotalEarnings,
          });
          
        }
        return item;
      });

      setShoppingCart(updatedCart);
      calculateTotals(updatedCart);

      if (currentUser) {
        saveCart();
      } else {
        localStorage.setItem('shoppingCart', JSON.stringify(updatedCart));
      }
    } else {
      setErrorMessage('Quantity must be greater than or equal to 1.');
    }
  };

  const emptyCart = () => {
    setModalMessage('Are you sure you want to empty the cart?');
    setModalAction(() => async () => {  // Use a callback to delay the action
      setShoppingCart([]);
      calculateTotals([]);
  
      if (currentUser) {
        try {
          const cartRef = doc(db, 'carts', currentUser.uid);
          await setDoc(cartRef, { shopping_cart: [] });
  
          const cartRtdbRef = ref(rtdb, `carts/${currentUser.uid}`);
          await update(cartRtdbRef, { shopping_cart: [] });
        } catch (error) {
          console.error('Error clearing cart in Firestore and Realtime Database:', error);
        }
      } else {
        localStorage.removeItem('shoppingCart');
      }
    });
    setIsModalOpen(true);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error('Failed to log out', error);
    }
  };

  const handleSignIn = () => {
    navigate('/auth');
  };

  const handleSignUp = () => {
    navigate('/auth', { state: { isLogin: false } });
  };

  const saveCart = async () => {
    if (currentUser) {
      await saveCartToFirestoreAndRTDB(shoppingCart);
    } else {
      localStorage.setItem('shoppingCart', JSON.stringify(shoppingCart));
    }
  };

  const saveCartToFirestoreAndRTDB = async (cart: CartItem[]) => {
    if (currentUser) {
      const cartRef = doc(db, 'carts', currentUser.uid);
      await setDoc(cartRef, {
        shopping_cart: cart,
        totalValues,
        totalQuantities
      });

      const cartRtdbRef = ref(rtdb, `carts/${currentUser.uid}`);
      await update(cartRtdbRef, {
        shopping_cart: cart,
        totalValues,
        totalQuantities
      });
    }
  };

  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-r from-green-50 to-green-100 text-gray-800">
<div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-b from-[#f5deb3] via-[#dbe7f1] to-[#1e2a38]">
<header className="w-full bg-[#f5deb3] shadow-md p-4 fixed top-0 left-0 flex justify-between items-center z-50 border-b border-gray-200">
          <div className="flex items-center justify-center flex-1">
            <Link to="/" className="text-2xl font-bold text-gray-900 flex items-center space-x-2">
              <img
                alt="BooksAreReborn Logo"
                src={image4}
                className="h-12 w-auto" // Adjusted logo size for the header
              />
              <span>BooksAreReborn</span>
            </Link>
            <nav className="hidden lg:flex space-x-4 mx-auto">
              <Link to="/" className="text-gray-900 hover:text-white hover:bg-gray-700 transition-colors duration-300 px-3 py-2 rounded-md text-lg font-medium">
                Home
              </Link>
              <Link to="/your-account" className="text-gray-900 hover:text-white hover:bg-gray-700 transition-colors duration-300 px-3 py-2 rounded-md text-lg font-medium">
                Account
              </Link>
              {currentUser && currentUser.email === '03omerbar@gmail.com' && (
                <Link to="/admin-panel" className="text-gray-900 hover:text-white hover:bg-gray-700 transition-colors duration-300 px-3 py-2 rounded-md text-lg font-medium">
                  Admin Panel
                </Link>
              )}
            </nav>
          </div>
          <div className="flex items-center space-x-4">
            {currentUser ? (
              <button onClick={handleLogout} className="hidden lg:block bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded-full shadow transition">
                Log out
              </button>
            ) : (
              <>
                <button onClick={handleSignIn} className="hidden lg:block bg-green-600 hover:bg-green-700 text-white px-4 py-2 rounded-full shadow transition">
                  Sign In
                </button>
                <button onClick={handleSignUp} className="hidden lg:block bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-full shadow transition">
                  Sign Up
                </button>
              </>
            )}
            <button
              type="button"
              onClick={() => setMobileMenuOpen(true)}
              className="lg:hidden p-2 rounded-full bg-gray-900 text-white"
            >
              <Bars3Icon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
        </header>

        <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
          <div className="fixed inset-0 z-50" />
          <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-[#f5deb3] px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <Link to="/" className="-m-1.5 p-1.5 flex items-center space-x-2">
                <img
                  alt="BooksAreReborn Logo"
                  src={image4}
                  className="h-12 w-auto" // Adjusted logo size for the mobile menu
                />
              </Link>
              <button
                type="button"
                onClick={() => setMobileMenuOpen(false)}
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
              >
                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  <Link
                    to="/"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Home
                  </Link>
                  <Link
                    to="/your-account"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Account
                  </Link>
                  {currentUser && currentUser.email === '03omerbar@gmail.com' && (
                    <Link
                      to="/admin-panel"
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      Admin Panel
                    </Link>
                  )}
                </div>
                <div className="py-6">
                  {currentUser ? (
                    <button onClick={handleLogout} className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                      Log out
                    </button>
                  ) : (
                    <>
                      <button onClick={handleSignIn} className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                        Sign In
                      </button>
                      <button onClick={handleSignUp} className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                        Sign Up
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </DialogPanel>
        </Dialog>

        <main className="pt-24 w-full max-w-4xl px-4">
        <ConfirmationModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={async () => {
          if (modalAction) {
            await modalAction();  // Execute the action only after confirm
          }
          setIsModalOpen(false);  // Close the modal
        }}
        message={modalMessage}
      />
        <section className="mb-8 p-8 rounded-xl shadow-2xl bg-white bg-opacity-80 backdrop-blur-lg">
  <motion.h1
    initial={{ opacity: 0, y: -50 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
    className="text-5xl font-bold text-gray-100 mb-6 p-6 rounded-lg shadow-lg bg-gradient-to-r from-[#0d3b66] to-[#145374] text-center tracking-wide"
  >
  TURN YOUR <span style={{ color: '#32CD32', textShadow: '0 0 10px rgba(50, 205, 50, 0.7)' }}>BOOKS</span> INTO <br /> <span style={{ color: '#FFD700', textShadow: '0 0 15px rgba(255, 215, 0, 1)' }}>CASH</span> <br />
  DON'T LET THEM GO TO<span style={{ color: '#FF4500', textShadow: '0 0 10px rgba(255, 69, 0, 0.8)' }}><br />WASTE</span>!
</motion.h1>
{errorMessage && (
  <motion.div
    initial={{ opacity: 0, y: -10 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: -10 }}
    transition={{ duration: 0.3 }}
    className="flex items-center justify-between max-w-lg mx-auto mb-6 p-4 bg-red-100 border border-red-400 text-red-700 rounded-lg shadow-md"
    role="setErrorMessage"
  >
    <div className="flex items-center space-x-2">
      {/* Icon for error */}
      <svg
        className="w-6 h-6 text-red-700"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M18.364 5.636a9 9 0 11-12.728 0M12 9v4m0 4h.01"
        ></path>
      </svg>
      <span className="font-semibold">{errorMessage}</span>
    </div>
    <button
      onClick={() => setErrorMessage('')}
      className="text-red-500 hover:text-red-800 transition-colors duration-200"
    >
      <XMarkIcon className="w-5 h-5" />
    </button>
  </motion.div>
)}


  <form method="post" onSubmit={handleSubmit} className="space-y-6">
    <input
      type="text"
      id="isbn"
      name="isbn"
      placeholder="Enter the ISBN value"
      required
      value={isbn}
      onChange={handleIsbnChange}
      className="w-full p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-300 transition-all duration-200"
    />

    {isbn && !isValidIsbn(isbn) && (
      <p className="text-red-500">
        Please enter a valid 10- or 13-digit ISBN number.
      </p>
    )}

    <input
      type="number"
      id="quantity"
      name="quantity"
      placeholder="Enter the quantity"
      min="1"
      value={quantity}
      onChange={handleQuantityChange}
      className="w-full p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-300 transition-all duration-200"
    />

    <motion.button
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      type="submit"
      className={`w-full py-4 bg-gradient-to-r from-[#28a745] to-[#28d47f] text-white font-semibold rounded-full shadow-lg hover:shadow-2xl transition-transform duration-300 ease-in-out ${
        !isValidIsbn(isbn) || totalValues < 6.13
          ? 'opacity-50 cursor-not-allowed'
          : ''
      }`}
      disabled={!isValidIsbn(isbn) || totalValues < 6.13}
    >
      Get Quote!
    </motion.button>
  </form>
</section>


<section className="mb-8 bg-gray-50 p-8 rounded-2xl shadow-2xl">
  <h2 className="text-4xl font-extrabold mb-8 text-gray-800 text-center">Shopping Cart</h2>

  {loading ? (
    renderOptimisticCart()
  ) : (
    <div className="relative overflow-x-auto shadow-xl rounded-lg border border-gray-200">
      <table className="w-full text-sm text-left text-gray-700 bg-gray-50">
        <thead className="text-xs text-gray-600 uppercase bg-gray-200">
          <tr>
            <th scope="col" className="px-16 py-3">
              <span className="sr-only">Image</span>
            </th>
            <th scope="col" className="px-6 py-3">Product</th>
            <th scope="col" className="px-6 py-3">ISBN</th>
            <th scope="col" className="px-6 py-3">Qty</th>
            <th scope="col" className="px-6 py-3">Price</th>
            <th scope="col" className="px-6 py-3">Action</th>
          </tr>
        </thead>
        <tbody className="bg-white">
          {shoppingCart.length > 0 ? (
            <>
              {shoppingCart.map((item, index) => (
                <tr
                  key={index}
                  className={`${
                    item.totalEarnings === 0
                      ? 'bg-red-50 text-red-600 border-red-300'
                      : 'bg-white border-b hover:bg-gray-100'
                  }`}
                >
                  <td className="p-4">
                    <img
                      src={item.imageLink}
                      className="w-16 md:w-32 max-w-full rounded-lg shadow-md"
                      alt={item.itemName}
                    />
                  </td>
                  <td className="px-6 py-4 font-semibold">{item.itemName}</td>
                  <td className="px-6 py-4">{item.isbn}</td>
                  <td className="px-6 py-4">
                    <div className="flex items-center space-x-2">
                      <button
                        onClick={() => decreaseQuantity(item.isbn)}
                        className="p-2 bg-red-500 text-white rounded-full shadow hover:bg-red-600 focus:ring-2 focus:ring-red-400"
                      >
                        <svg
                          className="w-4 h-4"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 18 2"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M1 1h16"
                          />
                        </svg>
                      </button>
                      <input
                        type="number"
                        id={`quantity${item.isbn}`}
                        className="w-12 p-2 border border-gray-300 rounded-lg text-center focus:ring-2 focus:ring-blue-500"
                        value={item.quantity}
                        onChange={(e) => changeQuantity(item.isbn, parseInt(e.target.value))}
                      />
                      <button
                        onClick={() => increaseQuantity(item.isbn)}
                        className="p-2 bg-green-500 text-white rounded-full shadow hover:bg-green-600 focus:ring-2 focus:ring-green-400"
                      >
                        <svg
                          className="w-4 h-4"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 18 18"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M9 1v16M1 9h16"
                          />
                        </svg>
                      </button>
                    </div>
                  </td>
                  <td className="px-6 py-4 font-bold text-gray-900">
                    ${(item.totalEarnings || 0).toFixed(2)}
                  </td>
                  <td className="px-6 py-4">
                    <a
                      href="#"
                      className="text-red-500 font-semibold hover:underline"
                      onClick={() => deleteItem(item.isbn)}
                    >
                      Remove
                    </a>
                  </td>
                </tr>
                ))}
                           <tr className="bg-gray-50 border-b hover:bg-gray-100">
                <td className="p-4">Total Length: {totalLength.toFixed(2)} inches</td>
                <td className="px-6 py-4 font-semibold">Total Width: {totalWidth.toFixed(2)} inches</td>
                <td className="px-6 py-4">Total Height: {totalHeight.toFixed(2)} inches</td>
                <td className="px-6 py-4">Total Items: {totalQuantities}</td>
                <td className="text-lg font-semibold text-green-600 flex-shrink-0">
                  Total Earnings: ${(totalValues || 0).toFixed(2)}
                </td>
                <td className="px-6 py-4">Total Weight: {totalWeight.toFixed(2)} pounds</td>
              </tr>
            </>
          ) : (
            <tr>
              <td colSpan={6} className="border px-4 py-2 text-center text-gray-600">
                Your cart is empty. Please add some items.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )}

  {shoppingCart.some(item => item.totalEarnings === 0) && (
    <div className="text-red-600 font-bold text-center mt-6">
      Some items are not accepted for offers at this time.
    </div>
  )}

  {shoppingCart.length > 0 && (
    <div className="mt-8">
      {totalValues < 6.13 && (
        <div className="text-red-600 font-bold text-center mb-4">
          You need at least $6.13 to proceed to checkout. Your total is: ${(totalValues || 0).toFixed(2)}
        </div>
      )}
      {totalValues > 100 && (
        <div className="text-red-600 font-bold text-center mb-4">
          Your cart exceeds the $100 limit. Please adjust to proceed.
        </div>
      )}
      <div className="flex justify-between mt-6">
        <button
          onClick={emptyCart}
          className="bg-red-500 text-white px-4 py-2 rounded-lg shadow-lg hover:bg-red-600 transition duration-300 transform hover:scale-105"
        >
          Empty Cart
        </button>

        {totalValues >= 6.13 && (
          <button
            onClick={() => {
              if (totalValues <= 100) {
                handleCreateShipment();
              }
            }}
            className={`bg-blue-500 text-white px-4 py-2 rounded-lg shadow-lg transition duration-300 transform hover:scale-105 ${
              totalValues > 100 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-600'
            }`}
            disabled={totalValues > 100}
          >
            Create Shipment
          </button>
        )}
      </div>
    </div>
  )}
</section>

</main>
      </div>
      <footer className="bg-gray-900 text-white py-10">
        <div className="container mx-auto px-6">
          <div className="flex flex-col md:flex-row justify-between items-center space-y-6 md:space-y-0">
            <div className="flex items-center space-x-4 flex-shrink-0">
              <img
                alt="BooksAreReborn Logo"
                src={image4}
                className="h-14 w-auto"
              />
              <span className="text-xl font-bold tracking-wide">BooksAreReborn</span>
            </div>
            <div className="flex flex-wrap justify-center space-x-4 md:space-x-8 text-center md:text-left">
              <Link
                to="/privacy-policy"
                className="text-sm hover:text-gray-400 transition duration-200"
              >
                Privacy Policy
              </Link>
              <Link
                to="/terms-of-use"
                className="text-sm hover:text-gray-400 transition duration-200"
              >
                Terms of Use
              </Link>
              <Link
                to="/acceptable-use"
                className="text-sm hover:text-gray-400 transition duration-200"
              >
                Acceptable Use
              </Link>
              <Link
                to="/contact-us"
                className="text-sm hover:text-gray-400 transition duration-200"
              >
                Contact Us
              </Link>
              <Link
                to="/buyback-guide"
                className="text-sm hover:text-gray-400 transition duration-200"
              >
                Buyback Guide
              </Link>
              <Link
                to="/faq"
                className="text-sm hover:text-gray-400 transition duration-200"
              >
                FAQs
              </Link>
            </div>
          </div>
          <div className="border-t border-gray-700 mt-8 pt-4">
            <div className="text-center text-gray-500 text-xs">
              &copy; {new Date().getFullYear()} BooksAreReborn. All rights reserved.
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Getoffer;