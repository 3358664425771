import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Dialog, DialogPanel } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { useAuth } from '../contexts/AuthContext';
import image4 from '../assets/images/image4.png'; // Import the logo image

const AcceptableUse: React.FC = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error('Failed to log out', error);
    }
  };

  const handleSignUpClick = () => {
    navigate('/auth', { state: { isLogin: false } });
  };

  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-b from-[#f5e4c3] via-[#cfd9df] to-[#121212]">
      <header className="w-full bg-[#f5deb3] shadow-md p-4 fixed top-0 left-0 flex justify-between items-center z-50 border-b border-gray-200">
        <div className="flex items-center justify-center flex-1">
          <Link to="/" className="text-2xl font-bold text-gray-900 flex items-center space-x-2">
            <img
              alt="BooksAreReborn Logo"
              src={image4}
              className="h-12 w-auto"
            />
            <span>BooksAreReborn</span>
          </Link>
          <nav className="hidden lg:flex space-x-4 mx-auto">
            <Link to="/" className="text-gray-900 hover:text-white hover:bg-gray-700 transition-colors duration-300 px-3 py-2 rounded-md text-lg font-medium">
              Home
            </Link>
            <Link to="/your-account" className="text-gray-900 hover:text-white hover:bg-gray-700 transition-colors duration-300 px-3 py-2 rounded-md text-lg font-medium">
              Your Account
            </Link>
            <Link to="/get-offer" className="text-gray-900 hover:text-white hover:bg-gray-700 transition-colors duration-300 px-3 py-2 rounded-md text-lg font-medium">
              Cart
            </Link>
            {/* <Link to="/admin-panel" className="text-gray-900 hover:text-white hover:bg-gray-700 transition-colors duration-300 px-3 py-2 rounded-md text-lg font-medium">
              Admin Panel
            </Link> */}
          </nav>
        </div>
        <div className="flex items-center space-x-4">
          {currentUser ? (
            <button
              onClick={handleLogout}
              className="hidden lg:block bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded-full shadow transition"
            >
              Log out
            </button>
          ) : (
            <>
              <button
                onClick={() => navigate('/auth')}
                className="hidden lg:block bg-green-600 hover:bg-green-700 text-white px-4 py-2 rounded-full shadow transition"
              >
                Sign In
              </button>
              <button
                onClick={handleSignUpClick}
                className="hidden lg:block bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-full shadow transition"
              >
                Sign Up
              </button>
            </>
          )}
          <button
            type="button"
            onClick={() => setMobileMenuOpen(true)}
            className="lg:hidden p-2 rounded-full bg-gray-900 text-white"
          >
            <Bars3Icon aria-hidden="true" className="h-6 w-6" />
          </button>
        </div>
      </header>

      <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
        <div className="fixed inset-0 z-50" />
        <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-[#f5deb3] px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <Link to="/" className="-m-1.5 p-1.5 flex items-center space-x-2">
              <img
                alt="BooksAreReborn Logo"
                src={image4}
                className="h-12 w-auto"
              />
            </Link>
            <button
              type="button"
              onClick={() => setMobileMenuOpen(false)}
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
            >
              <XMarkIcon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <Link to="/" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                  Home
                </Link>
                <Link to="/your-account" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                  Your Account
                </Link>
                <Link to="/get-offer" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                  Cart
                </Link>
                {/* <Link to="/admin-panel" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                  Admin Panel
                </Link> */}
              </div>
              <div className="py-6">
                {currentUser ? (
                  <button
                    onClick={handleLogout}
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Log out
                  </button>
                ) : (
                  <>
                    <button
                      onClick={() => navigate('/auth')}
                      className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      Sign In
                    </button>
                    <button
                      onClick={handleSignUpClick}
                      className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      Sign Up
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>

      <main className="flex-grow pt-24 flex justify-center items-center">
        <section className="w-full max-w-3xl bg-white p-6 rounded-lg shadow-lg text-center">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">Acceptable Use Policy</h1>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">1. These Terms</h2>
          <p className="text-gray-700 mb-4">
            Welcome to BooksAreReborn. These terms govern your use of our website and services. By using our services, you agree to comply with these terms and any other policies referenced within.
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">2. Who We Are and How to Contact Us</h2>
          <p className="text-gray-700 mb-4">
            BooksAreReborn is operated by TechBaR LLC. You can contact us at contact@booksarereborn.com. Please include relevant details when contacting us for support.
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">3. Privacy and Acceptable Use</h2>
          <p className="text-gray-700 mb-4">
            We collect and process your personal information in accordance with our Privacy Policy. By using our website, you agree to comply with our Acceptable Use Policy, ensuring that your actions do not harm others or disrupt our services.
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">4. This Contract and Your Promises</h2>
          <p className="text-gray-700 mb-4">
            When you create an account or make a purchase on our site, you enter into a contract with us. You promise that all information you provide is accurate, that you are the legal owner of any items you sell to us, and that you will not misuse our platform.
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">5. Acceptance Criteria for Items</h2>
          <p className="text-gray-700 mb-4">
            Items you wish to sell or trade must meet our acceptance criteria. This includes being in good condition, free from damage, and not being counterfeit or illegal. We reserve the right to reject items that do not meet these standards.
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">6. Rejection of Items and Cancellation of Trades</h2>
          <p className="text-gray-700 mb-4">
            We may reject items or cancel trades if they do not meet our criteria. If your items are rejected, we will notify you via email. Items that are rejected will not be returned and may be disposed of responsibly.
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">7. Valuation and Pricing of Your Items</h2>
          <p className="text-gray-700 mb-4">
            Our valuations are based on market conditions and may change. Prices quoted are valid for 24 hours. We strive to offer fair prices, but final valuations are at our discretion.
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">8. Shipping and Delivery</h2>
          <p className="text-gray-700 mb-4">
            When sending items to us, ensure they are properly packaged and shipped using our provided labels. We are not responsible for items lost or damaged in transit due to improper packaging.
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">9. Receipt, Ownership, Risk, and Processing of Items</h2>
          <p className="text-gray-700 mb-4">
            Ownership of items passes to us once we receive them. We will process items promptly and inform you of their acceptance or rejection. We are not liable for any loss or damage until we have received the items.
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">10. Payment Terms</h2>
          <p className="text-gray-700 mb-4">
            Payments are made for accepted items within 3-5 business days. Ensure your payment details are correct to avoid delays. We are not responsible for errors in payment due to incorrect information provided by you.
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">11. Offers, Bonuses, and Competitions</h2>
          <p className="text-gray-700 mb-4">
            From time to time, we may offer bonuses or run competitions. These offers are subject to terms and conditions, including minimum trade requirements. We reserve the right to withhold bonuses if the terms are not met.
          </p>
        </section>
        </main>
      <br />
      <footer className="bg-gray-900 text-white py-10">
  <div className="container mx-auto px-6">
    <div className="flex flex-col md:flex-row justify-between items-center space-y-6 md:space-y-0">
      <div className="flex items-center space-x-4 flex-shrink-0">
        <img
          alt="BooksAreReborn Logo"
          src={image4}
          className="h-14 w-auto"
        />
        <span className="text-xl font-bold tracking-wide">BooksAreReborn</span>
      </div>
      <div className="flex flex-wrap justify-center space-x-4 md:space-x-8 text-center md:text-left">
        <Link
          to="/privacy-policy"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          Privacy Policy
        </Link>
        <Link
          to="/terms-of-use"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          Terms of Use
        </Link>
        <Link
          to="/acceptable-use"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          Acceptable Use
        </Link>
        <Link
          to="/contact-us"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          Contact Us
        </Link>
        <Link
          to="/buyback-guide"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          Buyback Guide
        </Link>
        <Link
          to="/faq"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          FAQs
        </Link>
      </div>
    </div>
    <div className="border-t border-gray-700 mt-8 pt-4">
      <div className="text-center text-gray-500 text-xs">
        &copy; {new Date().getFullYear()} BooksAreReborn. All rights reserved.
      </div>
    </div>
  </div>
</footer>
    </div>
  );
};

export default AcceptableUse;
