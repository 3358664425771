import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Dialog, DialogPanel } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { useAuth } from '../contexts/AuthContext';
import image4 from '../assets/images/image4.png'; // Import the logo image

const FAQ: React.FC = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error('Failed to log out', error);
    }
  };

  const handleSignUpClick = () => {
    navigate('/auth', { state: { isLogin: false } });
  };

  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-b from-[#f5e4c3] via-[#cfd9df] to-[#121212]">
      <header className="w-full bg-[#f5deb3] shadow-md p-4 fixed top-0 left-0 flex justify-between items-center z-50 border-b border-gray-200">
        <div className="flex items-center justify-center flex-1">
          <Link to="/" className="text-2xl font-bold text-gray-900 flex items-center space-x-2">
            <img
              alt="BooksAreReborn Logo"
              src={image4}
              className="h-12 w-auto"
            />
            <span>BooksAreReborn</span>
          </Link>
          <nav className="hidden lg:flex space-x-4 mx-auto">
            <Link to="/" className="text-gray-900 hover:text-white hover:bg-gray-700 transition-colors duration-300 px-3 py-2 rounded-md text-lg font-medium">
              Home
            </Link>
            <Link to="/your-account" className="text-gray-900 hover:text-white hover:bg-gray-700 transition-colors duration-300 px-3 py-2 rounded-md text-lg font-medium">
              Your Account
            </Link>
            <Link to="/get-offer" className="text-gray-900 hover:text-white hover:bg-gray-700 transition-colors duration-300 px-3 py-2 rounded-md text-lg font-medium">
              Cart
            </Link>
          </nav>
        </div>
        <div className="flex items-center space-x-4">
          {currentUser ? (
            <button
              onClick={handleLogout}
              className="hidden lg:block bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded-full shadow transition"
            >
              Log out
            </button>
          ) : (
            <>
              <button
                onClick={() => navigate('/auth')}
                className="hidden lg:block bg-green-600 hover:bg-green-700 text-white px-4 py-2 rounded-full shadow transition"
              >
                Sign In
              </button>
              <button
                onClick={handleSignUpClick}
                className="hidden lg:block bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-full shadow transition"
              >
                Sign Up
              </button>
            </>
          )}
          <button
            type="button"
            onClick={() => setMobileMenuOpen(true)}
            className="lg:hidden p-2 rounded-full bg-gray-900 text-white"
          >
            <Bars3Icon aria-hidden="true" className="h-6 w-6" />
          </button>
        </div>
      </header>

      <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
        <div className="fixed inset-0 z-50" />
        <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-[#f5deb3] px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <Link to="/" className="-m-1.5 p-1.5 flex items-center space-x-2">
              <img
                alt="BooksAreReborn Logo"
                src={image4}
                className="h-12 w-auto"
              />
            </Link>
            <button
              type="button"
              onClick={() => setMobileMenuOpen(false)}
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
            >
              <XMarkIcon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <Link to="/" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                  Home
                </Link>
                <Link to="/your-account" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                  Your Account
                </Link>
                <Link to="/get-offer" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                  Cart
                </Link>
              </div>
              <div className="py-6">
                {currentUser ? (
                  <button
                    onClick={handleLogout}
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Log out
                  </button>
                ) : (
                  <>
                    <button
                      onClick={() => navigate('/auth')}
                      className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      Sign In
                    </button>
                    <button
                      onClick={handleSignUpClick}
                      className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      Sign Up
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>

      <main className="pt-28 w-full max-w-3xl px-6 mx-auto">
        <section className="mb-12 bg-white p-8 rounded-lg shadow-xl">
          <h1 className="text-center text-5xl font-extrabold text-gray-900 mb-8">Frequently Asked Questions</h1>
          <div className="space-y-8">
            <div>
              <h2 className="text-2xl font-bold text-gray-800">General Questions</h2>
              <p className="mt-2 text-lg text-gray-700">
                <strong>What is BooksAreReborn?</strong> <br />
                BooksAreReborn is an online platform where you can sell your used books. We help you find a new home for your books while you earn money from items you no longer need.
              </p>
              <p className="mt-4 text-lg text-gray-700">
                <strong>What types of books can I sell on BooksAreReborn?</strong> <br />
                You can sell any book that has a valid ISBN number. We do not accept media items like CDs, DVDs, or books without an ISBN.
              </p>
              <p className="mt-4 text-lg text-gray-700">
                <strong>Do you buy all types of books?</strong> <br />
                We focus on books with an ISBN number. We do not accept books that are in poor condition, advance reader copies (ARCs), or books marked "not for resale." Additionally, we do not purchase ex-library books, instructor editions, or international editions.
              </p>
            </div>

            <div>
              <h2 className="text-2xl font-bold text-gray-800">Selling Process</h2>
              <p className="mt-2 text-lg text-gray-700">
                <strong>How do I sell my books?</strong> <br />
                Simply enter the ISBN number of your book into the search box on our website. We will provide you with an automatic offer, and if you accept, you can send the book to us for payment.
              </p>
              <p className="mt-4 text-lg text-gray-700">
                <strong>What is an ISBN?</strong> <br />
                An ISBN (International Standard Book Number) is a unique 10 or 13-digit code used to identify books. It’s usually located on the barcode on the back cover of your book or on the publishing details page inside the book.
              </p>
              <p className="mt-4 text-lg text-gray-700">
                <strong>What condition should my books be in to sell them?</strong> <br />
                Books should be in good condition with no rips, tears, stains, or water damage. Pages should be intact, and the spine should be unbroken. Highlighting or writing is generally not accepted unless it is minimal or in textbooks.You may email "contact@booksarereborn.com" your book's condition before completing your shipment.
              </p>
              <p className="mt-4 text-lg text-gray-700">
                <strong>Can I sell textbooks?</strong> <br />
                Yes, you can sell textbooks as long as they have an ISBN number and are in good condition. Minimal highlighting or writing in textbooks is usually acceptable.
              </p>
            </div>

            <div>
              <h2 className="text-2xl font-bold text-gray-800">Payments and Offers</h2>
              <p className="mt-2 text-lg text-gray-700">
                <strong>How much will I get paid for my books?</strong> <br />
                The amount you receive depends on the book's condition, demand, and market value. Once you enter the ISBN, we’ll make you an offer that you can choose to accept.
              </p>
              <p className="mt-4 text-lg text-gray-700">
                <strong>How do I accept an offer?</strong> <br />
                After receiving an offer for your book(s), simply add the item to your trade and complete the process by finalizing your trade on the website.
              </p>
              <p className="mt-4 text-lg text-gray-700">
                <strong>How will I get paid?</strong> <br />
                We offer payment via PayPal or check. Once we have received and processed your books, we’ll issue your payment within 3-5 businees days.
              </p>
            </div>

            <div>
              <h2 className="text-2xl font-bold text-gray-800">Shipping and Packaging</h2>
              <p className="mt-2 text-lg text-gray-700">
                <strong>How do I ship my books to BooksAreReborn?</strong> <br />
                Once you've accepted our offer, package your books securely in a strong box and send them to us using the provided shipping label. Drop your package at your nearest USPS location.
              </p>
              <p className="mt-4 text-lg text-gray-700">
                <strong>Do I have to pay for shipping?</strong> <br />
                No, shipping is free when you use the label provided by BooksAreReborn.
              </p>
              <p className="mt-4 text-lg text-gray-700">
                <strong>How should I package my books?</strong> <br />
                Use a sturdy box that fits all the books you're sending. Ensure the books are secured inside the package to prevent movement during transit. Proper packaging helps avoid damage, ensuring you receive the full offer price.
              </p>
            </div>

            <div>
              <h2 className="text-2xl font-bold text-gray-800">Account and Support</h2>
              <p className="mt-2 text-lg text-gray-700">
                <strong>Do I need to create an account to sell my books?</strong> <br />
                Yes, creating an account allows you to track your sales, view past transactions, and manage your personal information easily.
              </p>
              <p className="mt-4 text-lg text-gray-700">
                <strong>What are the benefits of having an account?</strong> <br />
                With an account, you can save your details for faster transactions, track your sales, and receive special offers or notifications about promotions.
              </p>
              <p className="mt-4 text-lg text-gray-700">
                <strong>How do I contact customer support?</strong> <br />
                You can reach our customer support team via email at contact@booksarereborn.com. We’re here to help with any questions or concerns you may have.
              </p>
            </div>

            <div>
              <h2 className="text-2xl font-bold text-gray-800">Special Cases</h2>
              <p className="mt-2 text-lg text-gray-700">
                <strong>Can I sell large quantities of books?</strong> <br />
                We do not have a specific bulk buying program and can only accept one copy of each ISBN per transaction. If you have a large collection, please contact us for more information.
              </p>
              <p className="mt-4 text-lg text-gray-700">
                <strong>What if I send the wrong item by mistake?</strong> <br />
                Unfortunately, once an item is received, we cannot return it. Please double-check your items before sending them in.
              </p>
              <p className="mt-4 text-lg text-gray-700">
                <strong>What happens if my books are damaged during shipping?</strong> <br />
                If your books arrive damaged due to improper packaging, we may have to adjust the offer or reject the items. Please ensure that your books are packaged securely.
              </p>
            </div>

            <div>
              <h2 className="text-2xl font-bold text-gray-800">Policies and Procedures</h2>
              <p className="mt-2 text-lg text-gray-700">
                <strong>How secure is the shipping process?</strong> <br />
                We use USPS, a trusted shipping provider, and your items are insured up to their full value during transit. This ensures your books arrive safely and you receive the agreed payment.
              </p>
              <p className="mt-4 text-lg text-gray-700">
                <strong>How do you handle my personal information?</strong> <br />
                We take your privacy seriously. Please refer to our Privacy Policy for detailed information on how we protect and use your personal data.
              </p>
              <p className="mt-4 text-lg text-gray-700">
                <strong>Can I sell books from outside the US?</strong> <br />
                Currently, we only accept books from within the United States.
              </p>
            </div>
          </div>
        </section>
      </main>

      <footer className="bg-gray-900 text-white py-10">
  <div className="container mx-auto px-6">
    <div className="flex flex-col md:flex-row justify-between items-center space-y-6 md:space-y-0">
      <div className="flex items-center space-x-4 flex-shrink-0">
        <img
          alt="BooksAreReborn Logo"
          src={image4}
          className="h-14 w-auto"
        />
        <span className="text-xl font-bold tracking-wide">BooksAreReborn</span>
      </div>
      <div className="flex flex-wrap justify-center space-x-4 md:space-x-8 text-center md:text-left">
        <Link
          to="/privacy-policy"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          Privacy Policy
        </Link>
        <Link
          to="/terms-of-use"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          Terms of Use
        </Link>
        <Link
          to="/acceptable-use"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          Acceptable Use
        </Link>
        <Link
          to="/contact-us"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          Contact Us
        </Link>
        <Link
          to="/buyback-guide"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          Buyback Guide
        </Link>
        <Link
          to="/faq"
          className="text-sm hover:text-gray-400 transition duration-200"
        >
          FAQs
        </Link>
      </div>
    </div>
    <div className="border-t border-gray-700 mt-8 pt-4">
      <div className="text-center text-gray-500 text-xs">
        &copy; {new Date().getFullYear()} BooksAreReborn. All rights reserved.
      </div>
    </div>
  </div>
</footer>
    </div>
  );
};

export default FAQ;
