import React, { useState, useEffect } from 'react';
import cookiesImage from '../assets/images/cookies.webp';
import ga from '../ga-init'; 

const CookiesConsent: React.FC = () => {
  const [showConsent, setShowConsent] = useState(false);
  const [isInstagramBrowser, setIsInstagramBrowser] = useState(false); // New state to detect Instagram browser

  // Function to detect Instagram's in-app browser
  const detectInstagramBrowser = () => {
    const ua = window.navigator.userAgent || '';
    return ua.includes('Instagram');
  };

  useEffect(() => {
    // Check if user is using Instagram's in-app browser
    const isInstaBrowser = detectInstagramBrowser();
    setIsInstagramBrowser(isInstaBrowser);

    if (!isInstaBrowser) {
      const consent = localStorage.getItem('cookieConsent');
      if (consent === 'accepted') {
        ga.initGoogleAnalytics(); // Initialize GA only if consent is given
      } else if (!consent) {
        setTimeout(() => {
          setShowConsent(true); // Delay showing the consent banner
        }, 2000);
      }
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'accepted');
    setShowConsent(false);
    ga.initGoogleAnalytics(); // Initialize GA when accepted
  };

  const handleReject = () => {
    localStorage.setItem('cookieConsent', 'rejected');
    setShowConsent(false);
  };

  if (isInstagramBrowser) return null; // Skip the consent banner in Instagram's in-app browser
  if (!showConsent) return null; // Don't render the banner if consent is already given/rejected

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 text-white p-6 z-50 shadow-lg flex justify-between items-center space-x-6 rounded-t-lg">
      <div className="flex items-center justify-center">
        <img
          src={cookiesImage}
          alt="Cookies Character"
          className="w-16 h-16 object-contain" // Reduced size for mobile friendliness
        />
      </div>
      <div className="flex justify-between items-center w-full">
        <div className="max-w-md text-center md:text-left">
          <h3 className="text-lg font-bold mb-2">We Value Your Privacy</h3>
          <p className="text-sm">
            We use cookies to enhance your browsing experience, serve personalized content, and analyze our traffic. By clicking "Accept", you consent to the use of cookies, including analytics.
          </p>
        </div>
        <div className="flex space-x-4">
          <button
            onClick={handleAccept}
            className="bg-green-500 hover:bg-green-600 text-white px-6 py-3 rounded-full shadow-lg transition-transform transform hover:scale-105"
          >
            Accept All
          </button>
          <button
            onClick={handleReject}
            className="text-gray-300 hover:text-gray-100 border border-gray-400 px-6 py-3 rounded-full transition"
          >
            Reject
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookiesConsent;
