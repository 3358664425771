import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { auth, rtdb, db } from '../firebase';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
  sendEmailVerification,
  sendPasswordResetEmail,
  fetchSignInMethodsForEmail,
} from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { ref, onDisconnect, update } from 'firebase/database';
import ga from '../ga-init'; // Import Google Analytics
import Notification from '../components/Notification'; // Import Notification component


interface AuthProviderProps {
  children: ReactNode;
}

const AuthContext = createContext<any>(null);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [errorQueue, setErrorQueue] = useState<{ message: string, type: 'success' | 'error' | 'info' | 'warning' }[]>([]);
  const [currentError, setCurrentError] = useState<{ message: string, type: 'success' | 'error' | 'info' | 'warning' } | null>(null);
  const [showNotification, setShowNotification] = useState(false);
  const [inactivityTimeout, setInactivityTimeout] = useState<number | null>(null);


  // Function to show notifications sequentially from the error queue
  const showNotificationInOrder = () => {
    if (errorQueue.length > 0) {
      setCurrentError(errorQueue[0]);
      setShowNotification(true);
    }
  };

  // Add new notification to the error queue
  const addToErrorQueue = (message: string, type: 'success' | 'error' | 'info' | 'warning') => {
    setErrorQueue(prevQueue => prevQueue.concat({ message, type })); // Use concat to add new item
    if (!currentError) {
      showNotificationInOrder(); // Trigger showing the first notification
    }
  };
  

  // Handle notification close and show the next error in the queue
  const handleNotificationClose = () => {
    setShowNotification(false);
    setErrorQueue((prevQueue) => {
      const nextQueue = prevQueue.slice(1); // Remove the first error from the queue using slice
      if (nextQueue.length === 0) {
        setCurrentError(null); // No more errors to show
      } else {
        setCurrentError(nextQueue[0]); // Show the next error
      }
      return nextQueue; // Return the updated queue
    });
  };
  
  
  useEffect(() => {
    if (errorQueue.length > 0) {
      showNotificationInOrder();  // Show the next error when one is closed
    }
  }, [errorQueue]);

  // Monitor auth state and handle user login/logout
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        if (!user.emailVerified) {
          await signOut(auth);
          addToErrorQueue('Please verify your email before logging in.', 'error');
          setLoading(false);
          return;
        }

        const userStatusDatabaseRef = ref(rtdb, '/status/' + user.uid);
        const isOfflineForDatabase = {
          state: 'offline',
          last_changed: new Date().toISOString(),
        };

        const isOnlineForDatabase = {
          state: 'online',
          last_changed: new Date().toISOString(),
          email: user.email,
        };

        onDisconnect(userStatusDatabaseRef).set(isOfflineForDatabase).then(() => {
          update(userStatusDatabaseRef, isOnlineForDatabase);
        });

        setCurrentUser(user);

        const cookieConsent = localStorage.getItem('cookieConsent');
        if (cookieConsent === 'accepted') {
          ga.setUserId(user.uid);
        }
      } else {
        setCurrentUser(null);
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  // Sign up new users and send email verification
  const signup = async (email: string, password: string) => {
    try {
      const signInMethods = await fetchSignInMethodsForEmail(auth, email);

      if (signInMethods.includes(GoogleAuthProvider.PROVIDER_ID)) {
        addToErrorQueue('This email is already registered with Google Sign-In. Please log in using Google.', 'info');
        return;
      }

      if (signInMethods.includes('password')) {
        addToErrorQueue('This email is already registered with an email and password. Please log in.', 'info');
        return;
      }

      const result = await createUserWithEmailAndPassword(auth, email, password);

      await sendEmailVerification(result.user);
      addToErrorQueue('A verification email has been sent. Please verify your email before logging in.', 'success');

      await setDoc(doc(db, 'users', result.user.uid), { uid: result.user.uid, email: result.user.email });

      await signOut(auth);

      return result;
    } catch (error) {
      handleAuthError(error);
    }
  };

// Improved handleAuthError function
const handleAuthError = (error: any) => {
  console.error("Auth Error:", error); // Log the full error for debugging
  const errorCode = error.code || '';
  const errorMessage = error.message || '';

  if (errorCode) {
    switch (errorCode) {
      case 'auth/email-already-in-use':
        addToErrorQueue('This email is already registered. Please log in.', 'error');
        break;
      case 'auth/user-not-found':
        addToErrorQueue('User not found. Please check your credentials or sign up.', 'error');
        break;
      case 'auth/invalid-credential':
        addToErrorQueue('Incorrect password or email. Please try again.', 'error');
        break;
      case 'auth/too-many-requests':
        addToErrorQueue('Too many requests. Please wait and try again later.', 'warning');
        break;
      case 'auth/network-request-failed':
        addToErrorQueue('Network error. Please check your connection and try again.', 'error');
        break;
      default:
        addToErrorQueue(`Error: ${errorMessage}`, 'error');
        break;
    }
  } else {
    addToErrorQueue('Failed to authenticate. Please try again.', 'error');
  }
};


  // Login users but enforce email verification
  //C:\Users\omerb\OneDrive\Desktop\NewSeller\frontend\src\contexts\AuthContext.tsx
  const login = async (email: string, password: string, navigate: Function) => {
    console.log("Login credentials:", email, password);
    try {
      const result = await signInWithEmailAndPassword(auth, email, password);
  
      if (!result.user.emailVerified) {
        addToErrorQueue('You are logging in, please wait.', 'info');
        await signOut(auth);
        addToErrorQueue('Please verify your email before logging in.', 'error');
        return;
      }
  
      const cookieConsent = localStorage.getItem('cookieConsent');
      if (cookieConsent === 'accepted') {
        ga.trackEventBuilder('Auth')({ action: 'Login', label: email });
      }
  
      // Navigate to the get-offer page after successful login
      navigate('/get-offer');
  
      return result;
    } catch (error) {
      handleAuthError(error); // Use improved error handler
    }
  };
  
  

  const loginWithGoogle = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      const signInMethods = await fetchSignInMethodsForEmail(auth, user.email!);

      if (signInMethods.includes('password')) {
        addToErrorQueue('This email is already registered with an email and password. Please log in.', 'info');
        await signOut(auth);
        return;
      }

      await setDoc(doc(db, 'users', user.uid), { uid: user.uid, email: user.email, displayName: user.displayName, photoURL: user.photoURL }, { merge: true });

      if (!user.emailVerified) {
        await sendEmailVerification(user);
        await signOut(auth);
        throw new Error('Please verify your email before logging in.');
      }

      const cookieConsent = localStorage.getItem('cookieConsent');
      if (cookieConsent === 'accepted') {
        ga.trackEventBuilder('Auth')({ action: 'Login with Google', label: user.email || 'Google User' });
      }

      return result;
    } catch (error) {
      handleAuthError(error);
    }
  };

  const logout = async () => {
    if (currentUser) {
      const userStatusDatabaseRef = ref(rtdb, '/status/' + currentUser.uid);
      const isOfflineForDatabase = {
        state: 'offline',
        last_changed: new Date().toISOString(),
      };
      await update(userStatusDatabaseRef, isOfflineForDatabase);
    }

    const cookieConsent = localStorage.getItem('cookieConsent');
    if (cookieConsent === 'accepted') {
      ga.trackEventBuilder('Auth')({ action: 'Logout', label: currentUser && currentUser.email ? currentUser.email : 'User' });
    }

    return signOut(auth);
  };

  const resetInactivityTimeout = () => {
    if (inactivityTimeout) {
      console.log("Clearing previous timeout");
      clearTimeout(inactivityTimeout);
    }
  
    console.log("Setting new inactivity timeout");
  
    const timeout = setTimeout(async () => {
      if (currentUser) {
        await logout();
        console.log("User logged out due to inactivity.");
      }
    }, 15 * 60 * 1000); // 10 minutes of inactivity
  
    setInactivityTimeout(timeout);
  };
  

  // Event listeners for detecting activity
  useEffect(() => {
    const events = ['mousemove', 'keydown', 'scroll', 'touchstart', 'touchmove'];
    const resetTimeout = () => resetInactivityTimeout();

    events.forEach((event) => window.addEventListener(event, resetTimeout));
    
    return () => {
      events.forEach((event) => window.removeEventListener(event, resetTimeout));
    };
  }, [currentUser]);

  // Monitor auth state and handle user login/logout
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
        resetInactivityTimeout();
      } else {
        setCurrentUser(null);
      }
      setLoading(false);
    });
    return unsubscribe;
  }, []);

  const resetPassword = async (email: string) => {
    try {
      await sendPasswordResetEmail(auth, email);

      const cookieConsent = localStorage.getItem('cookieConsent');
      if (cookieConsent === 'accepted') {
        ga.trackEventBuilder('Auth')({ action: 'Password Reset', label: email });
      }

      addToErrorQueue('Password reset email sent.', 'success');
    } catch (error) {
      handleAuthError(error);
    }
  };

  return (
    <AuthContext.Provider value={{ currentUser, signup, login, loginWithGoogle, logout, resetPassword, handleAuthError,addToErrorQueue }}>
      {!loading && children}
      {currentError && (
        <Notification message={currentError.message} type={currentError.type} show={showNotification} onClose={handleNotificationClose} />
      )}
    </AuthContext.Provider>
  );
};

